// Documentação do API do Google Maps
// https://visgl.github.io/react-google-maps/docs/api-reference/components/marker
// Outros exemplos: https://visgl.github.io/react-google-maps/examples
// Adicionar pontos: https://github.com/visgl/react-google-maps/tree/main/examples/geometry
// Alterar tipo de Mapa: https://github.com/visgl/react-google-maps/tree/main/examples/change-map-styles

import {
  AdvancedMarker,
  Pin,
  APIProvider,
  Map,
} from "@vis.gl/react-google-maps";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Circle } from "./MapCircle";
import ControlPanel from "./MapControlPanel";

const MapTypeId = {
  HYBRID: "hybrid",
  ROADMAP: "roadmap",
  SATELLITE: "satellite",
  TERRAIN: "terrain",
};
export type MapConfig = {
  id: string;
  label: string;
  mapId?: string;
  mapTypeId?: string;
  styles?: google.maps.MapTypeStyle[];
};

const MAP_CONFIGS: MapConfig[] = [
  {
    id: "light",
    label: "Normal",
    mapId: process.env.REACT_APP_GOOGLE_MAPS_API_KEY_MAP_ID,
    mapTypeId: MapTypeId.ROADMAP,
  },
  {
    id: "satellite",
    label: "Satélite",
    mapId: process.env.REACT_APP_GOOGLE_MAPS_API_KEY_MAP_ID,
    mapTypeId: MapTypeId.SATELLITE,
  },
];

interface iPropsModal {
  show: boolean;
  handleClose: any;
  dia: any;
  marcacoes: any[];
  colaborador: any;
}

export default function ApuracaoModalMarcacoesDia({
  show,
  handleClose,
  dia,
  marcacoes,
  colaborador,
}: iPropsModal) {
  const [loading, setLoading] = useState<boolean>(true);
  const [primeiraMarcacao, setPrimeiraMarcacao] = useState<any>({});
  const [localTrabalho, setLocalTrabalho] = useState<any>({});
  const [localTrabalhoOrigem, setLocalTrabalhoOrigem] = useState<string>('');
  const [mapConfig, setMapConfig] = useState<MapConfig>(MAP_CONFIGS[0]);

  useEffect(() => {
    let plot = true;
    let all_latlng_is_null = true;
    let origemMarcacoes = false
    for (let reg of marcacoes) {
      if (reg.latitude === null) {
        continue;
      }
      if (reg.latitude !== null) {
        all_latlng_is_null = false
      }
      if (reg.id_local !== null && origemMarcacoes === false) {
        setLocalTrabalho(reg.id_local);
        setLocalTrabalhoOrigem('Marcações')
        origemMarcacoes = true
      }
      setPrimeiraMarcacao(reg);
      break;
    }
    if (all_latlng_is_null) {
      plot = false
    }

    if (colaborador.id_local !== null && origemMarcacoes === false) {
      if (colaborador.id_local.latitude !== null) {
        setLocalTrabalho(colaborador.id_local);
        setLocalTrabalhoOrigem('Colaborador')
      }
    }

    if (plot) {
      setLoading(false);
    }
  }, [marcacoes, colaborador]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Marcações de {dayjs(dia.data).format("DD/MM/YYYY")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading === false && Object.values(localTrabalho).length > 0 && (
          <div>
            <p>
              Local Trabalho: ({localTrabalho.id_local}) {localTrabalho.local} - Origem {localTrabalhoOrigem}
            </p>
            <p>
              Endereço: {localTrabalho.endereco}, {localTrabalho.numero},{" "}
              {localTrabalho.bairro}, {localTrabalho.cidade} Cep{" "}
              {localTrabalho.cep} / {localTrabalho.estado}
            </p>
            <p>Complemento: {localTrabalho.complemento}</p>
            <br />
          </div>
        )}
        
        {marcacoes.map((reg, index) => (
          <div key={index}>
            <p>{reg.tipo === 0 ? 'Entrada' : 'Saída'} {dayjs(reg.data_criacao).format("DD/MM/YYYY HH:mm:ss")} {reg.distancia_local_trabalho !== null ? ` - Distancia Local de Trabalho (metros): ${reg.distancia_local_trabalho}` : 'Não disponível'}</p>
          </div>
        ))}
          
        <br/>

        {loading === false && (
          <APIProvider
            apiKey={String(process.env.REACT_APP_GOOGLE_MAPS_API_KEY)}
          >
            <Map
              mapId={mapConfig.mapId || null}
              mapTypeId={mapConfig.mapTypeId}
              style={{ width: "700px", height: "700px" }}
              defaultCenter={{
                lat: Number(primeiraMarcacao.latitude),
                lng: Number(primeiraMarcacao.longitude),
              }}
              defaultZoom={16}
              gestureHandling={"greedy"}
              disableDefaultUI={true}
            >
              <ControlPanel
                mapConfigs={MAP_CONFIGS}
                mapConfigId={mapConfig.id}
                onMapConfigIdChange={(id) =>
                  setMapConfig(MAP_CONFIGS.find((s) => s.id === id)!)
                }
              />
              {marcacoes.map((reg: any, index: any) => {
                if (reg.latitude === null) {
                  return "";
                }
                return (
                  <AdvancedMarker
                    key={index}
                    position={{
                      lat: Number(reg.latitude),
                      lng: Number(reg.longitude),
                    }}
                  >
                    <Pin
                      background={reg.tipo === 0 ? "#0f9d58" : "#e5383b"}
                      borderColor={"#000000"}
                      glyphColor={reg.tipo === 0 ? "#60d98f" : "#a4161a"}
                      children={
                        <p
                          style={{
                            width: "max-content",
                            color: "white",
                            backgroundColor: "rgba(0,0,0,.2)",
                          }}
                        >
                          {dayjs(reg.data_criacao).format(
                            "DD/MM/YYYY HH:mm:ss",
                          )}
                        </p>
                      }
                    />
                  </AdvancedMarker>
                );
              })}
              {Object.values(localTrabalho).length > 0 && (
                <AdvancedMarker
                  position={{
                    lat: Number(localTrabalho.latitude),
                    lng: Number(localTrabalho.longitude),
                  }}
                >
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      background: "#000000",
                      border: "2px solid #000000",
                      borderRadius: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                </AdvancedMarker>
              )}
              {Object.values(localTrabalho).length > 0 && (
                <AdvancedMarker
                  position={{
                    lat: Number(localTrabalho.latitude),
                    lng: Number(localTrabalho.longitude),
                  }}
                >
                  <p
                    style={{
                      width: "max-content",
                      color: "white",
                      backgroundColor: "rgba(0,0,0,.2)",
                    }}
                  >
                    POSTO
                  </p>
                </AdvancedMarker>
              )}
              {Object.values(localTrabalho).length > 0 && (
                <Circle
                  center={{
                    lat: Number(localTrabalho.latitude),
                    lng: Number(localTrabalho.longitude),
                  }}
                  strokeColor={"#0c4cb3"}
                  strokeOpacity={1}
                  strokeWeight={3}
                  fillColor={"#3b82f6"}
                  fillOpacity={0.3}
                  radius={800}
                />
              )}
            </Map>
          </APIProvider>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
