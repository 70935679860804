import { useEffect, useState } from 'react'
import { ApiEndpoints, reqPost, reqGetNew, reqPatch } from '../../config/apiClient'
import { toast } from 'react-toastify'

import { Form, Button, Nav, ListGroup, Spinner } from 'react-bootstrap'

import styles from './styles/colaborador.module.css'
import MaskedInput from 'react-text-mask'
import dayjs from 'dayjs'
import ColaboradorModalEscala from './ColaboradorModalEscalas'

interface MenuItem {
    key: string,
    link: string,
    visibled: boolean,
    display: string
}

function loadDataSelect(input:string, dados:any, id:any) {

    var select = document.querySelector("#" + input)
    
    if (!select) { 
      return true
    }

    select!.innerHTML = `
        <option value="0">Não informado</option>
    `

    for (var i = 0; i < dados.length; i++) {

        var option = document.createElement("option")
        option.setAttribute("value", dados[i].id)
        option.innerText = dados[i].id + "- " + dados[i].descricao
        if (Number(dados[i].id) === Number(id)) {
            option.setAttribute("selected", "true")
        }
        select?.appendChild(option)

    }

}

function defineValueInInput(id:string, value:any) {
    let ele :any = document.getElementById(id)
    ele.value = value
}

function defineCheckedInput(id:string, value:boolean) {
    let ele :any = document.getElementById(id)
    ele.checked = value
}

function getValueFromInput(id:string) {
    let ele :any = document.getElementById(id)
    return ele.value
}

function getCheckedValueFromInput(id:string) {
    let ele :any = document.getElementById(id)
    return ele.checked === true ? 1 : 0
}

function ColaboradorPage() {

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showModalEscalas, setShowModalEscalas] = useState<boolean>(false)
    const [idColaborador, setIdColaborador] = useState<number | null>(null)
    const [colaboradorDados, setColaboradorDados] = useState<any>({})
    const [menu, setMenu] = useState<MenuItem[]>([
        {
            key: "dados_basicos",
            link: "link-0",
            visibled: true,
            display: "block"
        },
        {
            key: "funcionario",
            link: "link-1",
            visibled: false,
            display: "none"
        },
        {
            key: "acessos_aplicativo",
            link: "link-2",
            visibled: false,
            display: "none"
        }
    ])

    async function getColaboradorData(loadData:boolean) {

        const apiDados = await reqGetNew(ApiEndpoints.dadosFuncionario)
        console.log(apiDados.data)
        if (loadData) {
            loadDataSelect("escala", apiDados.data[0], "0")
            loadDataSelect("empresa", apiDados.data[1], "0")
            loadDataSelect("cargo", apiDados.data[2], "0")
            loadDataSelect("situacao", apiDados.data[3], "0")
            loadDataSelect("centro_custo", apiDados.data[4], "0")
            loadDataSelect("timezone", apiDados.data[5], "1")
            loadDataSelect("local_trabalho", apiDados.data[6], "0")
        }

        const urlString = String(window.location.href).split('?')
        const urlSearch = new URLSearchParams(urlString[1])

        const data = {
            "id": urlSearch.get('id')
        }

        if (data.id === null) {
            document.getElementById("titleMain")!.textContent = "Cadastrar Colaborador"
            document.title = `Cadastrar Colaborador | OB PORTUS`
            return
        }

        setIdColaborador(Number(data.id))

        const api = await reqPost(ApiEndpoints.colaborador, data)

        if (api.status === 0) {
            toast.warning('Erro de conexão!', {
                containerId: 1,
                theme: 'dark'
            })
        }

        if (api.status !== 0 && api.status !== 200) {
            toast.error('Erro ao consultar colaboradores! Entre em contato com o suporte ou tente mais tarde!', {
                containerId: 1,
                theme: 'dark'
            })
        }

        if (api.status === 200) {

            const col = api.data[0]
            console.log(col)
            setColaboradorDados(col)

            document.getElementById("nome_completo_matricula")!.textContent = `${col.nome_completo} | #${col.matricula}`
            document.title = `${col.nome_completo} | OB PORTUS`

            defineValueInInput("nome_completo", col.nome_completo)
            defineValueInInput("matricula", col.matricula)
            defineValueInInput("cpf", col.cpf)
            defineValueInInput("rg", col.rg)
            defineValueInInput("n_ctps", col.n_ctps)
            defineValueInInput("n_pis", col.n_pis)
            defineValueInInput("telefone", col.telefone)
            defineValueInInput("email", col.email)
            defineValueInInput("data_nascimento", col.data_nascimento)

            defineValueInInput("empresa", col.id_empresa)
            defineValueInInput("centro_custo", col.id_centro_custo)
            defineValueInInput("local_trabalho", col.id_local)
            defineValueInInput("situacao", col.id_situacao)
            defineValueInInput("data_admissao", col.data_admissao)
            defineValueInInput("data_demissao", col.data_demissao)
            defineValueInInput("cargo", col.id_cargo)
            defineValueInInput("timezone", col.id_tmz)
            defineValueInInput("token_acesso_app", col.token_app)

            defineValueInInput("data_ocorrencias_ativar", col.data_para_ativar_ponto)
            document.getElementById("btn_alterar_escala")!.textContent = `Escala: ${col.id_escala} - ${col.escala} (Clique aqui para alterar)`
            defineValueInInput("obs", col.obs_cadastro)

            defineCheckedInput("acesso_app", col.permitir_acesso_app === 1 ? true : false)
            defineCheckedInput("ponto_app", col.ponto_app === 1 ? true : false)
            defineCheckedInput("ocorrencias_app", col.gerar_pendencias_app === 1 ? true : false)

            defineCheckedInput("despesas_app", col.despesas_app === 1 ? true : false)
            defineCheckedInput("qrcode_app", col.qrcode_app === 1 ? true : false)
            defineCheckedInput("qrcode_config_app", col.qrcode_config_app === 1 ? true : false)
            defineCheckedInput("documentos_app", col.documentos_app === 1 ? true : false)
            defineCheckedInput("holerite_app", col.holerite_app === 1 ? true : false)
            defineCheckedInput("comunicado_app", col.comunicados_app === 1 ? true : false)
            defineCheckedInput("fale_conosco_app", col.comunicacao_app === 1 ? true : false)

            document.getElementById("status_app")!.textContent = col.acesso_app
            document.getElementById("cadastrado_em")!.textContent = col.data_cadastro_app === null ? '' : dayjs(col.data_cadastro_app).format("DD/MM/YYYY HH:mm:ss")
            document.getElementById("ultimo_cadastro_em")!.textContent = col.ultimo_cadastro_app === null ? '' : dayjs(col.ultimo_cadastro_app).format("DD/MM/YYYY HH:mm:ss")
            document.getElementById("ultimo_logoff_em")!.textContent = col.ultimo_logoff_app === null ? '' : dayjs(col.ultimo_logoff_app).format("DD/MM/YYYY HH:mm:ss")
            document.getElementById("versao_app")!.textContent = col.versao_app
            document.getElementById("modelo")!.textContent = col.modelo_celular_app
            document.getElementById("sistema")!.textContent = col.plataforma_app
            document.getElementById("versao_sistema")!.textContent = col.versao_android_app
            document.getElementById("token")!.textContent = col.token
            document.getElementById("ultimo_sincronizacao")!.textContent = col.data_ultima_atualizacao_app === null ? '' : dayjs(col.data_ultima_atualizacao_app).format("DD/MM/YYYY HH:mm:ss")
            document.getElementById("ultima_alteracao")!.textContent = col.data_ponto_alterado_app === null ? '' : dayjs(col.data_ponto_alterado_app).format("DD/MM/YYYY HH:mm:ss")
            document.getElementById("data_ponto_habilitado")!.textContent = col.data_ponto_ativado_app === null ? '' : dayjs(col.data_ponto_ativado_app).format("DD/MM/YYYY HH:mm:ss")

        }

    }

    useEffect(() => {
        document.title = 'Colaborador | OB PORTUS'
        document.getElementById("link-0")!.classList.add('active')
        getColaboradorData(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleMenuItemClick = (e:any) => {
        const index = Number(e.target.getAttribute('tabindex'))
        let menuItems = menu

        if (e.ctrlKey) {
            let item :MenuItem = menuItems[index]
            item.display = item.display === "none" ? "block" : "none"
            item.visibled = item.visibled === false ? true : false
            document.getElementById(item.key)!.style.display = item.display
            if (item.visibled) {
                document.getElementById(item.link)!.classList.add('active')
            } else {
                document.getElementById(item.link)!.classList.remove('active')
            }
            menuItems[index] = item
            setMenu(menuItems)
        } else {

            for (let i in menuItems) {

                if (Number(i) === index) {
                    let item :MenuItem = menuItems[index]

                    if (item.visibled) {
                        continue
                    }

                    item.display = item.display === "none" ? "block" : "none"
                    item.visibled = item.visibled === false ? true : false
                    document.getElementById(item.key)!.style.display = item.display
                    if (item.visibled) {
                        document.getElementById(item.link)!.classList.add('active')
                    } else {
                        document.getElementById(item.link)!.classList.remove('active')
                    }
                    menuItems[index] = item
                } else {
                    let item :MenuItem = menuItems[i]
                    item.display = "none"
                    item.visibled = false
                    document.getElementById(item.key)!.style.display = item.display
                    if (item.visibled) {
                        document.getElementById(item.link)!.classList.add('active')
                    } else {
                        document.getElementById(item.link)!.classList.remove('active')
                    }
                    menuItems[i] = item
                }

            }

            setMenu(menuItems)

        }

    }

    async function editBasicData() {

        const dados = {
            "id_colaborador": idColaborador,
            "nome_completo": getValueFromInput("nome_completo"),
            "matricula": Number(getValueFromInput("matricula")),
            "cpf": getValueFromInput("cpf"),
            "rg": getValueFromInput("rg"),
            "n_ctps": getValueFromInput("n_ctps"),
            "n_pis": getValueFromInput("n_pis"),
            "telefone": getValueFromInput("telefone"),
            "email": getValueFromInput("email"),
            "data_nascimento": getValueFromInput("data_nascimento"),

            "id_empresa": Number(getValueFromInput("empresa")),
            "id_centro_custo": Number(getValueFromInput("centro_custo")),
            "id_local": Number(getValueFromInput("local_trabalho")),
            "id_situacao": Number(getValueFromInput("situacao")),
            "data_admissao": getValueFromInput("data_admissao"),
            "data_demissao": getValueFromInput("data_demissao"),
            "id_cargo": Number(getValueFromInput("cargo")),
            "id_tmz": Number(getValueFromInput("timezone")),
        }

        if (dados.nome_completo === "") {
            toast.warning('Informe o nome completo do colaborador!', {
                containerId: 1,
                theme: 'dark',
                position: 'top-center'
            })
            return
        }

        if (dados.matricula === 0) {
            toast.warning('Informe a matricula do colaborador!', {
                containerId: 1,
                theme: 'dark',
                position: 'top-center'
            })
            return
        }

        if (dados.cpf === "") {
            toast.warning('Informe o CPF do colaborador!', {
                containerId: 1,
                theme: 'dark',
                position: 'top-center'
            })
            return
        }

        if (dados.id_empresa === 0) {
            toast.warning('Selecione a empresa do colaborador!', {
                containerId: 1,
                theme: 'dark',
                position: 'top-center'
            })
            return
        }

        if (dados.id_situacao === 0) {
            toast.warning('Selecione a situação do colaborador!', {
                containerId: 1,
                theme: 'dark',
                position: 'top-center'
            })
            return
        }

        if (dados.data_admissao === "") {
            toast.warning('Informe a data de admissão do colaborador!', {
                containerId: 1,
                theme: 'dark',
                position: 'top-center'
            })
            return
        }

        if (dados.id_cargo === 0) {
            toast.warning('Selecione o cargo do colaborador!', {
                containerId: 1,
                theme: 'dark',
                position: 'top-center'
            })
            return
        }

        if (dados.id_tmz === 0) {
            toast.warning('Selecione o timezone do colaborador!', {
                containerId: 1,
                theme: 'dark',
                position: 'top-center'
            })
            return
        }

        setIsLoading(true)

        const api = await reqPatch(ApiEndpoints.colaboradorEditarBasic, dados)

        setIsLoading(false)

        if (api.status === 0) {
            toast.warning('Erro de conexão!', {
                containerId: 1,
                theme: 'dark'
            })
            return
        }

        if (api.status === 200) {
            toast.success('Colaborador editado com sucesso!', {
                containerId: 1,
                theme: 'dark',
                position: 'top-center',
            })

            document.getElementById("nome_completo_matricula")!.textContent = `${dados.nome_completo} | #${dados.matricula}`
            document.title = `${dados.nome_completo} | OB PORTUS`
            getColaboradorData(false)
            return
        }
    
    }

    async function editAccessData() {

        let data = {
            "id_colaborador": idColaborador,
            "acesso_app": getCheckedValueFromInput("acesso_app"),
            "ponto_app": getCheckedValueFromInput("ponto_app"),
            "ocorrencias_app": getCheckedValueFromInput("ocorrencias_app"),
            "data_ocorrencias_ativar": getValueFromInput("data_ocorrencias_ativar"),
            "senha_app": getValueFromInput("senha_app"),
            "despesas_app": getCheckedValueFromInput("despesas_app"),
            "qrcode_app": getCheckedValueFromInput("qrcode_app"),
            "qrcode_config_app": getCheckedValueFromInput("qrcode_config_app"),
            "senha_qrcode_app": getValueFromInput("senha_qrcode_app"),
            "documentos_app": getCheckedValueFromInput("documentos_app"),
            "holerite_app": getCheckedValueFromInput("holerite_app"),
            "comunicado_app": getCheckedValueFromInput("comunicado_app"),
            "fale_conosco_app": getCheckedValueFromInput("fale_conosco_app"),
            "obs": getValueFromInput("obs"),
            "token_app": getValueFromInput("token_acesso_app"),
        }

        if (String(data.senha_qrcode_app).length > 8) {
            toast.warning('A senha do QRCODE não pode ser maior que 8 caracteres!', {
                containerId: 1,
                theme: 'dark',
                position: 'top-center'
            })
            return
        }

        if (data.token_app !== "") {
            data.token_app = Number(data.token_app)
    
            if (data.token_app < 11111 || data.token_app > 99999) {
                toast.warning('Informe um número válido para o token de acesso!', {
                    containerId: 1,
                    theme: 'dark',
                    position: 'top-center'
                })
                return
            }
        }

        setIsLoading(true)

        const api = await reqPost(ApiEndpoints.colaboradorEditAccess, data)

        setIsLoading(false)

        if (api.status === 0) {
            toast.warning('Erro de conexão!', {
                containerId: 1,
                theme: 'dark'
            })
            return
        }

        if (api.status !== 0 && api.status !== 200) {
            toast.error('Erro ao consultar colaboradores! Entre em contato com o suporte ou tente mais tarde!', {
                containerId: 1,
                theme: 'dark'
            })
            return
        }

        if (api.status === 200) {
            toast.success('Permissões editadas com sucesso!', {
                containerId: 1,
                theme: 'dark',
                position: 'top-center',
            })
            defineValueInInput("senha_app", "")
            defineValueInInput("senha_qrcode_app", "")
            getColaboradorData(false)

            return
        }

    }

    function handleUpdateEscala() {
        getColaboradorData(false)
    }

    async function handleCadastrar() {

        let dadosForm = {
            matricula: Number(getValueFromInput("matricula")),
            nome_completo: getValueFromInput("nome_completo"),
            cpf: getValueFromInput("cpf"),
            obs_cadastro: getValueFromInput("obs"),
            rg: getValueFromInput("rg"),
            n_ctps: getValueFromInput("n_ctps"),
            n_pis: getValueFromInput("n_pis"),
            telefone: getValueFromInput("telefone"),
            email: getValueFromInput("email"),
            data_nascimento: getValueFromInput("data_nascimento"),
            data_admissao: getValueFromInput("data_admissao"),
            data_demissao: getValueFromInput("data_demissao"),
            id_empresa: Number(getValueFromInput("empresa")),
            id_cargo: Number(getValueFromInput("cargo")),
            id_escala: Number(getValueFromInput("escala")),
            id_situacao: Number(getValueFromInput("situacao")),
            id_centro_custo: Number(getValueFromInput("centro_custo")),
            id_tmz: Number(getValueFromInput("timezone")),
            id_local: Number(getValueFromInput("local_trabalho")),
            permitir_acesso_app: getCheckedValueFromInput("acesso_app"),
            documentos_app: getCheckedValueFromInput("documentos_app"),
            holerite_app: getCheckedValueFromInput("holerite_app"),
            comunicados_app: getCheckedValueFromInput("comunicado_app"),
            comunicacao_app: getCheckedValueFromInput("fale_conosco_app"),
            ponto_app: getCheckedValueFromInput("ponto_app"),
            despesas_app: getCheckedValueFromInput("despesas_app"),
            gerar_pendencias_app: getValueFromInput("data_ocorrencias_ativar") === "" ? null : getValueFromInput("data_ocorrencias_ativar"),
            qrcode_app: getCheckedValueFromInput("qrcode_app"),
            qrcode_config_app: getCheckedValueFromInput("qrcode_config_app"),
            senha_app: getValueFromInput("senha_app"),
            senha_qrcode_app: getValueFromInput("senha_qrcode_app"),
        }
        console.log(dadosForm)

        if (dadosForm['matricula'] === 0) {
            mensagemAlerta("Necessário informar o campo de matrícula!")
            return
        }
    
        if (dadosForm['nome_completo'] === '') {
            mensagemAlerta("Necessário informar o campo de Nome Completo!")
            return
        }
    
        if (dadosForm['cpf'] === '') {
            mensagemAlerta("Necessário informar o campo de CPF!")
            return
        }
    
        if (dadosForm['data_nascimento'] === '') {
            mensagemAlerta("Necessário informar o campo de Data Nascimento!")
            return
        }
    
        if (dadosForm['data_admissao'] === '') {
            mensagemAlerta("Necessário informar o campo de Data Admissão!")
            return
        }
    
        if (dadosForm['id_empresa'] === 0) {
            mensagemAlerta("Necessário informar o campo de Empresa!")
            return
        }
    
        if (dadosForm['id_centro_custo'] === 0) {
            mensagemAlerta("Necessário informar o campo de Centro de Custo!")
            return
        }
    
        if (dadosForm['id_cargo'] === 0) {
            mensagemAlerta("Necessário informar o campo de Cargo!")
            return
        }
    
        if (dadosForm['id_escala'] === 0) {
            mensagemAlerta("Necessário informar o campo de Escala!")
            return
        }
    
        if (dadosForm['id_situacao'] === 0) {
            mensagemAlerta("Necessário informar o campo de Situação!")
            return
        }

        if (dadosForm['id_tmz'] === 0) {
            mensagemAlerta("Necessário informar o campo de Timezone!")
            return
        }
    
        if (dadosForm['senha_app'] === '') {
            mensagemAlerta("Necessário informar o campo de Senha App!")
            return
        }
    
        if (dadosForm['qrcode_app'] === 1 && dadosForm['senha_qrcode_app'] === '') {
            mensagemAlerta("Necessário informar o campo de Senha App QR CODE!")
            return
        }

        setIsLoading(true)
        
        const api = await reqPost(ApiEndpoints.colaboradorCadastrar, dadosForm)
        
        if (api.status === 201) {
            toast.success('Colaborador cadastrado com sucesso!', {
                containerId: 1,
                theme: 'dark',
                position: 'top-center',
            })
            window.location.assign(`/colaboradores`)
        } else {
            toast.error('Erro ao tentar fazer o cadastro!', {
                containerId: 1,
                theme: 'dark',
                position: 'top-center',
            })
        }
        
        setIsLoading(false)
    }

    function mensagemAlerta(mensagem:string) {
        var alerta = document.createElement("div")
        alerta.innerHTML =
                `
                    <div class="alert alert-warning" id="validacaoAlerta" role="alert" style="position:fixed; left: 0; right: 0; margin: 0 auto; width: 420px; text-align: center; z-index: 10; top: 10px;">
                        <div>
                            ${mensagem}
                        </div>
                    </div>
                `
                
        alerta.setAttribute("id", "alertaDiv")
        document.querySelector("body")!.appendChild(alerta)
                
        setTimeout(function() {
            document.getElementById("alertaDiv")!.remove()
        }, 5000)
    }

    return (
      <div id='main'>

            { Object.values(colaboradorDados).length > 0 && <ColaboradorModalEscala show={showModalEscalas} colaboradorDados={colaboradorDados} handleClose={() => setShowModalEscalas(false)} handleUpdate={handleUpdateEscala} /> }

            <h1 id='titleMain'>Editar Colaborador: <span id='nome_completo_matricula'></span></h1>

            <br></br>

            <Nav variant="tabs">
            <Nav.Item>
                <Nav.Link id="link-0" onClick={handleMenuItemClick} href='' tabIndex={0} active={menu[1].visibled} className={styles.linkTab}>Dados básicos</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link id="link-1" onClick={handleMenuItemClick} href='' tabIndex={1} active={menu[1].visibled} className={styles.linkTab}>Funcionário</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link id="link-2" onClick={handleMenuItemClick} href='' tabIndex={2} active={menu[2].visibled} className={styles.linkTab}>Acessos Aplicativo</Nav.Link>
            </Nav.Item>
            </Nav>

            <br></br>

            <div className='container-fluid p-0 m-0' id={menu[0].key} style={{display: menu[0].display}}>

                {/* 
                    Dados basicos
                    <div className='row p-0 m-0'>
                        <div className='col-12 p-0'>
                            <div className={styles.divTitleSection}>
                                <h2 className={styles.titleSection}>Dados Básicos</h2>
                            </div>
                        </div>
                    </div>
                */}
                
                <div className='row p-0 m-0'>

                    <div className='col-9' style={{ padding: '0px 10px 0px 0px' }}>
                        <p className={styles.inputLabel}>Nome Completo:</p>
                        <Form.Control id='nome_completo' type="text" autoComplete='off' />
                    </div>

                    <div className='col-3 p-0'>
                        <p className={styles.inputLabel}>Matrícula:</p>
                        <Form.Control id='matricula' type="text" autoComplete='off' />
                    </div>

                </div>
                <br></br>
                <div className='row p-0 m-0'>

                    <div className='col-3' style={{ padding: '0px 10px 0px 0px' }} >
                        <p className={styles.inputLabel}>CPF:</p>
                        {/* <Form.Control id='cpf' type="text" autoComplete='off' /> */}
                        <MaskedInput
                            mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/ ]}
                            guide={false}
                            className="form-control"
                            id='cpf'
                            autoComplete='off'
                            type='text'
                        />
                    </div>

                    <div className='col-3' style={{ padding: '0px 10px 0px 0px' }} >
                        <p className={styles.inputLabel}>RG:</p>
                        <Form.Control id='rg' type="text" autoComplete='off' />
                    </div>

                    <div className='col-3' style={{ padding: '0px 10px 0px 0px' }} >
                        <p className={styles.inputLabel}>Nº CTPS:</p>
                        {/* <Form.Control id='n_ctps' type="text" autoComplete='off' /> */}
                        <MaskedInput
                            mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                            guide={false}
                            className="form-control"
                            id='n_ctps'
                            autoComplete='off'
                            type='text'
                        />
                    </div>

                    <div className='col-3 p-0'>
                        <p className={styles.inputLabel}>Nº PIS:</p>
                        {/* <Form.Control id='n_pis' type="text" autoComplete='off' /> */}
                        <MaskedInput
                            mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                            guide={false}
                            className="form-control"
                            id='n_pis'
                            autoComplete='off'
                            type='text'
                        />
                    </div>

                </div>
                <br></br>
                <div className='row p-0 m-0'>

                    <div className='col-4' style={{ padding: '0px 10px 0px 0px' }} >
                        <p className={styles.inputLabel}>Telefone:</p>
                        {/* <Form.Control id='telefone' type="text" autoComplete='off' /> */}
                        <MaskedInput
                            mask={['(', /[0-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            guide={false}
                            className="form-control"
                            id='telefone'
                            autoComplete='off'
                            type='text'
                        />
                    </div>

                    <div className='col-4' style={{ padding: '0px 10px 0px 0px' }} >
                        <p className={styles.inputLabel}>Email:</p>
                        <Form.Control id='email' type="text" autoComplete='off' color='danger' />
                    </div>

                    <div className='col-4 p-0'>
                        <p className={styles.inputLabel}>Data Nascimento:</p>
                        <Form.Control id='data_nascimento' type="date" autoComplete='off' />
                    </div>

                </div>
                <br></br>
                <div className='row p-0 m-0'>
                    <div className='col-12 p-0'>
                        {
                            idColaborador !== null &&
                            <button type="button" className="btn btn-success" onClick={editBasicData} disabled={isLoading}>
                                {
                                    isLoading ?
                                    <span>Salvar dados básicos <Spinner animation="border" variant="light" size='sm'/></span> :
                                    <span>Salvar dados básicos</span>
                                }
                            </button>
                        }
                        {
                            idColaborador === null &&
                            <Button variant='success' onClick={() => { handleCadastrar() }}>{
                                isLoading ? "Aguarde..." : "Cadastrar Colaborador"
                            }</Button>
                        }
                    </div>
                </div>
                <br></br>

            </div>

            <div className='container-fluid p-0 m-0' id={menu[1].key} style={{display: menu[1].display}}>

                {/* 
                    Dados funcionário
                    <div className='row p-0 m-0'>
                        <div className='col-12 p-0'>
                            <div className={styles.divTitleSection}>
                                <h2 className={styles.titleSection}>Funcionário</h2>
                            </div>
                        </div>
                    </div>
                */}

                <div className='row p-0 m-0'>
                    <div className='col-3' style={{ padding: '0px 10px 0px 0px' }} >
                        <p className={styles.inputLabel}>Empresa:</p>
                        {/* <Form.Control id='empresa' type="text" autoComplete='off' /> */}
                        <select className="form-select" id='empresa' aria-label="Default select example">
                            <option value="0">Não informado</option>
                        </select>
                    </div>

                    <div className='col-3' style={{ padding: '0px 10px 0px 0px' }} >
                        <p className={styles.inputLabel}>Centro de Custo:</p>
                        {/* <Form.Control id='centro_custo' type="text" autoComplete='off' /> */}
                        <select className="form-select" id='centro_custo' aria-label="Default select example">
                            <option value="0">Não informado</option>
                        </select>
                    </div>

                    <div className='col-3' style={{ padding: '0px 10px 0px 0px' }} >
                        <p className={styles.inputLabel}>Local de Trabalho:</p>
                        {/* <Form.Control id='local_trabalho' type="text" autoComplete='off' /> */}
                        <select className="form-select" id='local_trabalho' aria-label="Default select example">
                            <option value="0">Não informado</option>
                        </select>
                    </div>

                    <div className='col-3 p-0'>
                        <p className={styles.inputLabel}>Situação:</p>
                        {/* <Form.Control id='situacao' type="text" autoComplete='off' /> */}
                        <select className="form-select" id='situacao' aria-label="Default select example">
                            <option value="0">Não informado</option>
                        </select>
                    </div>
                </div>
                <br></br>
                <div className='row p-0 m-0'>
                    <div className='col-3' style={{ padding: '0px 10px 0px 0px' }} >
                        <p className={styles.inputLabel}>Data de admissão:</p>
                        <Form.Control id='data_admissao' type="date" autoComplete='off' />
                    </div>

                    <div className='col-3' style={{ padding: '0px 10px 0px 0px' }} >
                        <p className={styles.inputLabel}>Data de demissão:</p>
                        <Form.Control id='data_demissao' type="date" autoComplete='off' />
                    </div>

                    <div className='col-3' style={{ padding: '0px 10px 0px 0px' }} >
                        <p className={styles.inputLabel}>Cargo:</p>
                        {/* <Form.Control id='cargo' type="text" autoComplete='off' /> */}
                        <select className="form-select" id='cargo' aria-label="Default select example">
                            <option value="0">Não informado</option>
                        </select>
                    </div>

                    <div className='col-3 p-0'>
                        <p className={styles.inputLabel}>Timezone:</p>
                        {/* <Form.Control id='timezone' type="text" autoComplete='off' /> */}
                        <select className="form-select" id='timezone' aria-label="Default select example">
                            <option value="0">Não informado</option>
                        </select>
                    </div>
                </div>
                <br></br>
                <div className='row p-0 m-0'>
                    <div className='col-12 p-0'>
                        {
                            idColaborador !== null &&
                            <button type="button" className="btn btn-success" onClick={editBasicData} disabled={isLoading}>
                                {
                                    isLoading ?
                                    <span>Salvar dados funcionário <Spinner animation="border" variant="light" size='sm'/></span> :
                                    <span>Salvar dados funcionário</span>
                                }
                            </button>
                        }
                    </div>
                </div>
                <br></br>

            </div>

            <div className='container-fluid p-0 m-0' id={menu[2].key} style={{display: menu[2].display}}>

                {/* <div className='row p-0 m-0'>
                    <div className='col-12 p-0'>
                        <div className={styles.divTitleSection}>
                            <h2 className={styles.titleSection}>Acessos</h2>
                        </div>
                    </div>
                </div> */}

                <div className='row p-0 m-0'>
                    <div className='col-6' style={{ padding: '0px 10px 0px 0px' }}>
                        <Form.Check
                            type="switch"
                            id="acesso_app"
                            label="Permitir acesso ao aplicativo"
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            type="switch"
                            id="ponto_app"
                            label="Ponto Digital"
                            className={styles.inputCheck}
                        />
                        <p style={{marginBottom: "10px"}}><a href={`/ponto/apuracao?id=${idColaborador}&inicial=${dayjs().startOf('month').format("YYYY-MM-DD")}&final=${dayjs().endOf('month').format("YYYY-MM-DD")}`} target="_blank" rel="noreferrer">Link para Apuração do Ponto</a></p>
                        <Form.Check
                            type="switch"
                            id="ocorrencias_app"
                            label="Gerar ocorrências?"
                            className={styles.inputCheck}
                        />
                        <p className={styles.inputLabel}>Ativar ocorrências automaticamente em:</p>
                        <Form.Control id='data_ocorrencias_ativar' type="date" autoComplete='off' />
                        <br></br>
                        {
                            idColaborador === null && 
                            <div>
                                <p className={styles.inputLabel}>Escala:</p>
                                <select className="form-select" id='escala' aria-label="Default select example">
                                    <option value="0">Não informado</option>
                                </select>
                                <br></br>
                            </div>
                        }
                        {
                            idColaborador !== null &&
                            <Button id="btn_alterar_escala" onClick={() => setShowModalEscalas(true)} variant="outline-success" style={{marginBottom: '13px'}}>Escala: --- (Clique aqui para alterar)</Button>
                        }
                        <p className={styles.inputLabel}>Senha:</p>
                        <Form.Control id='senha_app' type="password" autoComplete='off' autoCapitalize='off' />
                    </div>
                    <div className='col-6'>
                        <Form.Check
                            type="switch"
                            id="despesas_app"
                            label="Gestão de Despesas"
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            type="switch"
                            id="qrcode_app"
                            label="QR Code Scanner"
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            type="switch"
                            id="qrcode_config_app"
                            label="QR Code Scanner CONFIG"
                            className={styles.inputCheck}
                        />
                        <p className={styles.inputLabel}>Senha QR Code:</p>
                        <Form.Control id='senha_qrcode_app' type="password" autoComplete='off' autoCapitalize='off' style={{marginBottom: '10px'}} />
                        <Form.Check
                            type="switch"
                            id="documentos_app"
                            label="Documentos"
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            type="switch"
                            id="holerite_app"
                            label="Holerite"
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            type="switch"
                            id="comunicado_app"
                            label="Comunicados"
                            className={styles.inputCheck}
                        />
                        <Form.Check
                            type="switch"
                            id="fale_conosco_app"
                            label="Fale Conosco"
                            className={styles.inputCheck}
                        />
                    </div>
                </div>
                <br></br>
                <div className='row p-0 m-0'>
                    <div className='col-12 p-0'>
                        <p className={styles.inputLabel}>Observação:</p>
                        <textarea className="form-control" id="obs" style={{height: "128px"}} maxLength={500}></textarea>
                    </div>
                </div>
                <br></br>
                <div className='row p-0 m-0'>
                    <div className='col-6 p-0'>
                        <p className={styles.inputLabel}>Token de Acesso:</p>
                        <Form.Control id='token_acesso_app' type="text" autoComplete='off' autoCapitalize='off' />
                    </div>
                </div>
                <br></br>
                <div className='row p-0 m-0'>
                    <div className='col-12 p-0'>
                        {
                            idColaborador !== null &&
                            <button type="button" className="btn btn-success" onClick={editAccessData} disabled={isLoading}>
                                {
                                    isLoading ?
                                    <span>Salvar dados de acesso <Spinner animation="border" variant="light" size='sm'/></span> :
                                    <span>Salvar dados de acesso</span>
                                }
                            </button>
                        }
                    </div>
                </div>
                <br></br>
                <div className='row p-0 m-0'>
                    <div className='col-12 p-0'>
                    <ListGroup horizontal>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}}><b>Status:</b></ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}} id='status_app'>---</ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}}><b>Cadastrado em:</b></ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}} id='cadastrado_em'>---</ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}}><b>Último cadastro em:</b></ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}} id='ultimo_cadastro_em'>---</ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}}><b>Último Logoff em:</b></ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}} id='ultimo_logoff_em'>---</ListGroup.Item>
                    </ListGroup>
                    <ListGroup horizontal>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}}><b>Versão App:</b></ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}} id='versao_app'>---</ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}}><b>Modelo Smartphone:</b></ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}} id='modelo'>---</ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}}><b>Sistema:</b></ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}} id='sistema'>---</ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}}><b>Versão sistema:</b></ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}} id='versao_sistema'>---</ListGroup.Item>
                    </ListGroup>
                    <ListGroup horizontal>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}}><b>Token App:</b></ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}} id='token'>---</ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}}><b>Última sincronização:</b></ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}} id='ultimo_sincronizacao'>---</ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}}><b>Último alteração ponto:</b></ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}} id='ultima_alteracao'>---</ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}}><b>Data ponto habilitado:</b></ListGroup.Item>
                        <ListGroup.Item style={{width: '-webkit-fill-available'}} id='data_ponto_habilitado'>---</ListGroup.Item>
                    </ListGroup>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default ColaboradorPage