import { FormEvent, useEffect, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import removeAllErrorMsg from "../../../components/funcRemoveAllErros"
import Select from "react-select"
import $ from 'jquery'
import 'jquery-mask-plugin'
import styles from '../styles/inicial.module.css'
import stylesModelo from '../styles/modelo.module.css'
import { ApiEndpoints, reqGetNew, reqPatch, reqPost, reqPostNew, reqPut } from "../../../config/apiClient"
import LoadingModal from "../../../components/loadingModal"
import { defineValueInInput } from "../../../components/funcForInput"
import { toast } from "react-toastify"
import { NfModelosCadastrarDto } from "../dto/nf_modelos"
import funcSleep from "../../../components/funcSleep"
import { getUrlSearchParams } from "../../urlParams"

const customSelectStylesNfs = {
    control: (baseStyles:any, state:any) => ({
        ...baseStyles,
        borderColor: "#dee2e6",
        backgroundColor: state.isDisabled ? "#E9ECEF" : "white",
    }),
    singleValue: (provided:any, state:any) => ({
        ...provided,
        color: state.isDisabled ? "black" : "black",
    }),
}

interface iFiltros {
    nf_cidades: any[],
    nf_empresas: any[],
    nf_tipos_clientes: any[],
    nf_servicos: any[],
    nf_servicos_empresas: any[],
    nf_clientes: any[],
}

function formatarDinheiro(valor:string):string {
    defineValueInInput("dinheiro_aux", valor)
    return $("#dinheiro_aux").masked(valor)
}

function formatarPorcentagem(valor:string):string {
    defineValueInInput("porcentagem_aux", valor)
    return $("#porcentagem_aux").masked(valor)
}

function getInputPorcentagem(id:string):string {
    let input:any = document.getElementById(id)
    let value:string = input.value
    if (value === "" || value === "0") {
        value = "0.0"
    }
    return value
}
function getInputDinheiro(id:string):string {
    let input:any = document.getElementById(id)
    let value:string = input.value
    if (value === "") {
        value = "0,0"
    }
    return value.replaceAll(".", "").replaceAll(",", ".")
}

export default function NfCadastrarModeloPage() {
    useEffect(() => {
        $('.dinheiro').mask('###.###.###.###.###.###.##0,00', {reverse: true, placeholder: "0,00"});
        $('.porcentagem').mask('000.00', {reverse: true, placeholder: "0.00"});
        document.title = "NF Cadastrar Modelo | OB PORTUS"
        
        const loadPage = async () => {
            let filtros = await getFiltros()
            const urlDados = getUrlSearchParams()
            const id = urlDados.get("id")
            if (id !== null) {
                getModelo(Number(id), filtros)
            }
        }
        loadPage()
        // eslint-disable-next-line
    }, [])

    const [idModelo, setIdModelo] = useState<number | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [optanteSimplesNacional, setOptanteSimplesNacional] = useState<boolean>(false)
    const [filtros, setFiltros] = useState<iFiltros>({
        nf_cidades: [],
        nf_empresas: [],
        nf_tipos_clientes: [],
        nf_servicos: [],
        nf_servicos_empresas: [],
        nf_clientes: [],
    })

    const [idEmpresa, setIdEmpresa] = useState<any>({ "value": "0", "label": "Não Selecionado" })
    const [idEmpresaOptions, setIdEmpresaOptions] = useState<any[]>([])
    const [codCidade, setCodCidade] = useState<any>({ "value": "0", "label": "Não Selecionado" })
    const [codCidadeOptions, setCodCidadeOptions] = useState<any[]>([])
    const [idCliente, setIdCliente] = useState<any>({ "value": "0", "label": "Não Selecionado" })
    const [idClienteOptions, setIdClienteOptions] = useState<any[]>([])
    const [codServ, setCodServ] = useState<any>({ "value": "0", "label": "Não Selecionado" })
    const [codServOptions, setCodServOptions] = useState<any[]>([])

    async function getFiltros():Promise<iFiltros> {
        const apiFiltros = await reqGetNew(ApiEndpoints.nfFiltros)
        if (apiFiltros.status === 200) {
            setFiltros(apiFiltros.data)
            let empresasAux:any[] = apiFiltros.data.nf_empresas.map((item:any, index:any) => (
                {
                    "value": `${item.id_empresa}`,
                    "label": `${item.id_empresa} ${item.nome} - CNPJ ${item.cnpj}`
                }
            ))
            setIdEmpresaOptions(empresasAux)
            let cidadesAux = apiFiltros.data.nf_cidades.map((item:any, index:any) => (
                {
                    "value": `${item.cod_cidade}`,
                    "label": `(${item.cod_cidade}) ${item.nome}/${item.uf} - Cep ${item.cep}`
                }
            ))
            setCodCidadeOptions(cidadesAux)
            return apiFiltros.data
        }
        return filtros
    }

    async function getModelo(id_modelo: number, filtros:iFiltros):Promise<void> {
        setLoading(true)
        const api = await reqPost(ApiEndpoints.nfModeloConsultar, {id_modelo: id_modelo})
        if (api.status === 200) {
            setIdModelo(api.data.id_modelo)
            console.log(api.data)
            document.title = `NF Modelo ${api.data.nome} | OB PORTUS`
            document.getElementById("titleMain")!.textContent = "Editar Modelo"

            const modelo = api.data

            setIdEmpresa({
                "value": modelo.id_empresa.id_empresa,
                "label": `${modelo.id_empresa.id_empresa} ${modelo.id_empresa.nome} - CNPJ ${modelo.id_empresa.cnpj}`
            })
            handleChangeEmpresa({
                "value": modelo.id_empresa.id_empresa,
                "label": `${modelo.id_empresa.id_empresa} ${modelo.id_empresa.nome} - CNPJ ${modelo.id_empresa.cnpj}`
            }, filtros)
            setIdCliente({
                "value": modelo.id_cliente.id_cliente,
                "label": `${modelo.id_cliente.id_cliente} ${modelo.id_cliente.nome} - CNPJ ${modelo.id_cliente.cnpj}`
            })
            setCodServ({
                "value": modelo.cod_serv.cod_serv,
                "label": `${modelo.cod_serv.cod_serv} - ${modelo.cod_serv.descricao} - ${modelo.cod_serv.iss_incidencia_municipio_prestador === 1 ? "Retido" : "Tributado"}`
            })
            setCodCidade({
                "value": modelo.cod_cidade.cod_cidade,
                "label": `(${modelo.cod_cidade.cod_cidade}) ${modelo.cod_cidade.nome}/${modelo.cod_cidade.uf} - Cep ${modelo.cod_cidade.cep}`
            })

            defineValueInInput("nome_modelo", modelo.nome)
            
            defineValueInInput("valor", formatarDinheiro(modelo.valor))
            defineValueInInput("data_servico", modelo.dia_data_servico)
            defineValueInInput("data_competencia", modelo.dia_data_competencia)
            defineValueInInput("referencia", modelo.referencia === null ? "0" : String(modelo.referencia))
            defineValueInInput("situacao", modelo.cod_serv.iss_incidencia_municipio_prestador === 1 ? "Retido" : "Tributado")
            defineValueInInput("descriminacao", modelo.descriminacao)

            let form:any = document.getElementById("formNfModelo")

            form.tipo.value = modelo.tipo

            form.IRRF.value = formatarPorcentagem(modelo.IRRF)
            form.IRRF_V.value = formatarDinheiro(modelo.IRRF_V)

            form.INSS.value = formatarPorcentagem(modelo.INSS)
            form.INSS_V.value = formatarDinheiro(modelo.INSS_V)

            form.COFINS.value = formatarPorcentagem(modelo.COFINS)
            form.COFINS_V.value = formatarDinheiro(modelo.COFINS_V)

            form.CSLL.value = formatarPorcentagem(modelo.CSLL)
            form.CSLL_V.value = formatarDinheiro(modelo.CSLL_V)

            form.PIS.value = formatarPorcentagem(modelo.PIS)
            form.PIS_V.value = formatarDinheiro(modelo.PIS_V)

            form.ISS.value = formatarPorcentagem(modelo.ISS)
            form.ISS_V.value = formatarDinheiro(modelo.ISS_V)

            handleCalcTributos()
            defineValueInInput("BASE_V", modelo.valor)
        }
        setLoading(false)
    }

    function getServicosEmpresa(dados:iFiltros, id_empresa:number):any[] {
        let servicos:any[] = []
        // let 
        let cod_serv_ids:string[] = []

        for (let reg of dados.nf_servicos_empresas) {
            if (reg.id_empresa === id_empresa) {
                cod_serv_ids.push(reg.cod_serv)
            }
        }

        if (cod_serv_ids.length === 0) {
            return dados.nf_servicos
        }

        for (let cod_serv of cod_serv_ids) {
            servicos.push(dados.nf_servicos.filter((reg:any) => reg.cod_serv === cod_serv)[0])
        }
        
        return servicos
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        
        event.preventDefault()
        event.stopPropagation()
        removeAllErrorMsg()
        const form:any = event.target
        let formValidado = true

        let dados:NfModelosCadastrarDto = {
            id_modelo: idModelo,
            nome: form.nome_modelo.value,
            tipo: Number(form.tipo.value),
            id_empresa: Number(idEmpresa.value),
            id_cliente: Number(idCliente.value),
            cod_serv: codServ.value,
            cod_cidade: Number(codCidade.value),
            valor: getInputDinheiro("valor"),
            PIS: getInputPorcentagem("PIS"),
            PIS_V: getInputDinheiro("PIS_V"),
            COFINS: getInputPorcentagem("COFINS"),
            COFINS_V: getInputDinheiro("COFINS_V"),
            INSS: getInputPorcentagem("INSS"),
            INSS_V: getInputDinheiro("INSS_V"),
            IRRF: getInputPorcentagem("IRRF"),
            IRRF_V: getInputDinheiro("IRRF_V"),
            CSLL: getInputPorcentagem("CSLL"),
            CSLL_V: getInputDinheiro("CSLL_V"),
            ISS: getInputPorcentagem("ISS"),
            ISS_V: getInputDinheiro("ISS_V"),
            dia_data_servico: form.data_servico.value,
            dia_data_competencia: form.data_competencia.value,
            descriminacao: form.descriminacao.value,
            referencia: Number(form.referencia.value),
            situacao: 1
        }

        if (dados.nome === "") {
            form.nome_modelo.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.tipo === 0) {
            form.tipo_aux.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.id_empresa === 0) {
            form.empresa_aux.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.id_cliente === 0) {
            form.cliente_aux.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.cod_serv === "0") {
            form.servico_aux.nextElementSibling.style.display = "block"
            formValidado = false
        }
        
        if (dados.cod_cidade === 0) {
            form.cidade_aux.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.valor === "0.0") {
            form.valor.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.dia_data_servico === "") {
            form.data_servico.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.dia_data_competencia === "") {
            form.data_competencia.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.descriminacao === "") {
            form.descriminacao.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.referencia !== 0) {
            let referencia_definida = String(dados.descriminacao).search("#ref#")
            console.log(referencia_definida)
            if (referencia_definida < 0) {
                console.log("referencia_nao_encontrada")
                form.referencia.nextElementSibling.style.display = "block"
                formValidado = false
            }
        }

        if (optanteSimplesNacional) {
            if (dados.ISS === "0.00") {
                toast.error('Informar valor de ISS!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            }
        }

        console.log(dados)

        if (!formValidado) {
            return
        }

        if (dados.id_modelo === null) {
            cadastrar(dados)
        }

        if (dados.id_modelo !== null) {
            editar(dados)
        }

    }

    async function cadastrar(dados:NfModelosCadastrarDto) {
        setLoading(true)
        const api = await reqPut(ApiEndpoints.nfModeloCadastrar, dados)
        if (api.status === 201) {
            console.log(api.data)
            toast.success('Modelo cadastrado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            await funcSleep(500)
            window.location.assign(`/nf/modelos`)
        }
        setLoading(false)
    }

    async function editar(dados:NfModelosCadastrarDto) {
        setLoading(true)
        const api = await reqPatch(ApiEndpoints.nfModeloEditar, dados)
        if (api.status === 200) {
            console.log(api.data)
            toast.success('Modelo editado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            await funcSleep(500)
            window.location.assign(`/nf/modelos`)
        }
        setLoading(false)
    }

    async function deletar() {
        if (!window.confirm("Tem certeza que deseja excluir o modelo?")) {
            return
        }
        setLoading(true)
        const api = await reqPostNew(ApiEndpoints.nfModeloDeletar, {id_modelo: idModelo})
        if (api.status === 200) {
            console.log(api.data)
            toast.success('Modelo excluído com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            await funcSleep(500)
            window.location.assign(`/nf/modelos`)
        }
        setLoading(false)
    }

    function handleChangeEmpresa(choice:any, filtros:iFiltros): void {
        setLoading(true)
        setIdCliente({ "value": "0", "label": "Não Selecionado" })
        let clientesFiltro:any[] = filtros.nf_clientes.filter((reg:any) => reg.id_empresa === Number(choice.value))
        let clientesAux = clientesFiltro.map((item:any, index:any) => (
            {
                "value": `${item.id_cliente}`,
                "label": `${item.id_cliente} ${item.nome} - CNPJ ${item.cnpj}`
            }
        ))
        setIdClienteOptions(clientesAux)
        setCodServ({ "value": "0", "label": "Não Selecionado" })
        let nf_servicos_empresa = getServicosEmpresa(filtros, Number(choice.value))
        let codServsAux = nf_servicos_empresa.map((item:any, index:any) => (
            {
                "value": `${item.cod_serv}`,
                "label": `${item.cod_serv} - ${item.descricao} - ${item.iss_incidencia_municipio_prestador === 1 ? "Retido" : "Tributado"}`
            }
        ))
        setCodServOptions(codServsAux)
        setCodCidade({ "value": "0", "label": "Não Selecionado" })
        const empresa = filtros.nf_empresas.filter(reg => reg.id_empresa === Number(choice.value))[0]
        console.log(filtros.nf_empresas)
        if (empresa.optante_simples_nacional === 1) {
            defineValueInInput("ISS", empresa.ISS)
            document.getElementById("ISS_descricao")!.textContent = "ISS (Optante Simples Nacional)"
            setOptanteSimplesNacional(true)
            handleCalcTributo("ISS")
            handleCalcValorLiquido()
        } else {
            defineValueInInput("ISS", "")
            document.getElementById("ISS_descricao")!.textContent = "ISS"
            setOptanteSimplesNacional(false)
            handleCalcTributo("ISS")
            handleCalcValorLiquido()
        }
        setLoading(false)
    }

    function handleChangeInValor(event:any): void {
        handleCalcTributos()
        defineValueInInput("BASE_V", event.target.value)
    }
    function handleChangeInTributo(event:any): void {
        handleCalcTributo(event.target.id)
        handleCalcValorLiquido()
    }
    function handleCalcTributos() {
        let input_valor:any = document.getElementById("valor")
        if (input_valor.value === "") {
            return
        }
        handleCalcTributo("IRRF")
        handleCalcTributo("INSS")
        handleCalcTributo("COFINS")
        handleCalcTributo("CSLL")
        handleCalcTributo("PIS")
        handleCalcTributo("ISS")
        handleCalcValorLiquido()
    }
    function handleCalcTributo(id_input_tributo:string) {
        let input_tributo:any = document.getElementById(id_input_tributo)
        let input_valor:any = document.getElementById("valor")
        if (input_valor.value === "") {
            return
        }
        let valor_nota_string:string = String(input_valor.value)
        let valor_nota:number = Number(valor_nota_string.replaceAll(".", "").replaceAll(",", "."))
        let valor_tributo:number = valor_nota * (Number(input_tributo.value) / 100)
        let input_tributo_valor:any = document.getElementById(`${id_input_tributo}_V`)
        input_tributo_valor.value = formatarDinheiro(String(valor_tributo.toFixed(2)))
    }
    function handleCalcValorLiquido() {
        let input_valor:any = document.getElementById("valor")
        if (input_valor.value === "") {
            return
        }
        let valor_nota_string:string = String(input_valor.value)
        let valor_nota:number = Number(valor_nota_string.replaceAll(".", "").replaceAll(",", "."))
    
        let inputIRRF:any = document.getElementById("IRRF")
        let IRRF:number = valor_nota * (Number(inputIRRF.value) / 100)
    
        let inputINSS:any = document.getElementById("INSS")
        let INSS:number = valor_nota * (Number(inputINSS.value) / 100)
    
        let inputCOFINS:any = document.getElementById("COFINS")
        let COFINS:number = valor_nota * (Number(inputCOFINS.value) / 100)
    
        let inputCSLL:any = document.getElementById("CSLL")
        let CSLL:number = valor_nota * (Number(inputCSLL.value) / 100)
    
        let inputPIS:any = document.getElementById("PIS")
        let PIS:number = valor_nota * (Number(inputPIS.value) / 100)
    
        let inputISS:any = document.getElementById("ISS")
        let ISS:number = valor_nota * (Number(inputISS.value) / 100)
    
        let ISS_status:any = document.getElementById("situacao")
    
        let valor_impostos:number = (IRRF + INSS + COFINS + CSLL + PIS + ISS)
        valor_impostos = valor_impostos >= valor_nota ? valor_nota : valor_impostos
        let valor_liquido:number = valor_nota - (valor_impostos)
    
        if (ISS_status.value === "Tributado") {
            valor_liquido = valor_nota
        }
    
        let input_valor_liquido:any = document.getElementById("LIQUIDO_V")
        input_valor_liquido.value = formatarDinheiro(String(valor_liquido.toFixed(2)))
    }

    return (
        <div id="main">
            <div className="div-row-space-between">
                <h1 id="titleMain">Cadastrar Modelo</h1>
                <Button onClick={() => { window.location.assign(`/nf/modelos`) } }>Voltar</Button>
            </div>
            <br></br>

            <LoadingModal show={loading} handleClose={(event:any) => { setLoading(!loading) }} />

            <Form id='formNfModelo' onSubmit={handleSubmit} noValidate>
            
            <Row className="mb-15-forms">
                <Col className="col-6">
                    <Form.Group>
                        <Form.Label>Nome do Modelo:</Form.Label>
                        <Form.Control name='nome_modelo' type="text" id='nome_modelo' />
                        <Form.Control.Feedback type="invalid">
                            Informe o nome do modelo!
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className="col-6">
                    <Form.Group style={{fontWeight: "bold"}}>
                        <Form.Label><b>CRIAR NOTA MENSALMENTE ?</b></Form.Label>
                        <br></br>
                        <Form.Check
                            inline
                            label="Mensal"
                            value="1"
                            name="tipo"
                            type="radio"
                            style={{marginTop: "0px"}}
                            id={`inline-radio-1-tipo`}
                            className={stylesModelo.formRadios}
                        />
                        <Form.Check
                            inline
                            label="Não, salvar apenas modelo"
                            value="2"
                            name="tipo"
                            type="radio"
                            id={`inline-radio-2-tipo`}
                            className={stylesModelo.formRadios}
                        />
                        <Form.Control name='tipo_aux' type="text"id='tipo_aux' placeholder="" style={{display:"none"}}/>
                        <Form.Control.Feedback type="invalid">
                            Informe o tipo do modelo!
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

            <Row className="mb-15-forms">
                <Col className="col-6">
                    <Form.Group>
                        <Form.Label>Empresa:</Form.Label>
                        <Select name='empresa' id='empresa' options={idEmpresaOptions} value={idEmpresa} placeholder="Selecione a empresa" onChange={
                            (choice:any) => {
                                setIdEmpresa(choice)
                                handleChangeEmpresa(choice, filtros)
                            }
                        } styles={customSelectStylesNfs} />
                        <Form.Control name='empresa_aux' type="text"id='empresa_aux' placeholder="" style={{display:"none"}}/>
                        <Form.Control.Feedback type="invalid">
                            Selecione a Empresa!
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className="col-6">
                    <Form.Group>
                        <Form.Label>Cliente:</Form.Label>
                        <Select name='cliente' id='cliente' options={idClienteOptions} value={idCliente} placeholder="Selecione o cliente" onChange={
                            (choice:any) => {
                                setIdCliente(choice)
                                let cliente = filtros.nf_clientes.filter(reg => reg.id_cliente === Number(choice.value))[0]
                                setCodCidade(
                                    {
                                        "value": `${cliente.cod_cidade.cod_cidade}`,
                                        "label": `${cliente.cod_cidade.nome}/${cliente.cod_cidade.uf} -Cep ${cliente.cod_cidade.cep}`
                                    }
                                )
                                setCodServ({ "value": "0", "label": "Não Selecionado" })
                                if (!optanteSimplesNacional) {
                                    let tipo_cliente = filtros.nf_tipos_clientes.filter(reg => reg.id_tipo_cliente === cliente.id_tipo_cliente)[0]
                                    defineValueInInput("IRRF", formatarPorcentagem(tipo_cliente.IRRF))
                                    handleCalcTributo("IRRF")
                                    defineValueInInput("INSS", formatarPorcentagem(tipo_cliente.INSS))
                                    handleCalcTributo("INSS")
                                    defineValueInInput("COFINS", formatarPorcentagem(tipo_cliente.COFINS))
                                    handleCalcTributo("COFINS")
                                    defineValueInInput("CSLL", formatarPorcentagem(tipo_cliente.CSLL))
                                    handleCalcTributo("CSLL")
                                    defineValueInInput("PIS", formatarPorcentagem(tipo_cliente.PIS))
                                    handleCalcTributo("PIS")
                                    defineValueInInput("ISS", formatarPorcentagem(cliente.cod_cidade.iss))
                                    handleCalcTributo("ISS")
                                    handleCalcValorLiquido()
                                    toast.info(`Tipo de Cliente selecionado: ${tipo_cliente.nome}!`, {
                                        containerId: 1,
                                        position: 'top-center',
                                        theme: 'dark',
                                        autoClose: 2000,
                                    })
                                }
                            }
                        } styles={customSelectStylesNfs} />
                        <Form.Control name='cliente_aux' type="text"id='cliente_aux' placeholder="" style={{display:"none"}}/>
                        <Form.Control.Feedback type="invalid">
                            Selecione o Cliente!
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

            <br></br>

            <Row className="mb-15-forms">
                <Col className="col-2">
                    <Form.Group>
                        <Form.Label>Valor:</Form.Label>
                        <Form.Control name='valor' type="text" id='valor' className='dinheiro' autoComplete='off' onKeyUp={handleChangeInValor} placeholder="0,00" style={{fontWeight: "bold"}}/>
                        <Form.Control.Feedback type="invalid">
                            Informe o valor da NF!
                        </Form.Control.Feedback>
                        <Form.Control name='dinheiro_aux' type="text" id='dinheiro_aux' className='dinheiro' placeholder="0,00" style={{display: "none"}} />
                        <Form.Control name='porcentagem_aux' type="text" id='porcentagem_aux' className='porcentagem' placeholder="0.00" style={{display: "none"}} />
                    </Form.Group>
                </Col>
                <Col className="col-2">
                    <Form.Group>
                        <Form.Label>Dia Data Serviço:</Form.Label>
                        <Form.Control name='data_servico' type="number" id='data_servico' placeholder="Ex.: 1 ou 19" />
                        <Form.Control.Feedback type="invalid">
                            Informe a data do serviço!
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className="col-2">
                    <Form.Group>
                        <Form.Label>Dia Data Competência:</Form.Label>
                        <Form.Control name='data_competencia' type="number" id='data_competencia' placeholder="Ex.: 1 ou 19" />
                        <Form.Control.Feedback type="invalid">
                            Informe a data da competência!
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className="col-6">
                    <Form.Group>
                        <Form.Label>Serviço:</Form.Label>
                        <Select name='servico' id='servico' options={codServOptions} value={codServ} placeholder="Selecione o servico" onChange={
                            (choice:any) => {
                                setCodServ(choice)
                                let servico = filtros.nf_servicos.filter(reg => reg.cod_serv === choice.value)[0]
                                defineValueInInput("situacao", servico.iss_incidencia_municipio_prestador === 1 ? "Retido" : "Tributado")
                                handleCalcTributos()
                            }
                        } styles={customSelectStylesNfs} />
                        <Form.Control name='servico_aux' type="text"id='servico_aux' placeholder="" style={{display:"none"}}/>
                        <Form.Control.Feedback type="invalid">
                            Selecione o Serviço!
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

            <Row className="mb-15-forms">
                <Col className='col-2'>
                    <p className={styles.titleValorTributo}>IRRF</p>
                    <div className={styles.divInputsTributo}>
                        <p>Aliquota (%)</p>
                        <input name='IRRF' type="text" id='IRRF' className='porcentagem' onKeyUp={handleChangeInTributo} autoComplete='off' placeholder="0.00"/>
                    </div>
                    <div className={styles.divInputsTributo}>
                        <p>Valor (R$)</p>
                        <input name='IRRF_V' type="text" id='IRRF_V' placeholder="0,00" readOnly disabled/>
                    </div>
                </Col>
                <Col className='col-2'>
                    <p className={styles.titleValorTributo}>INSS</p>
                    <div className={styles.divInputsTributo}>
                        <p>Aliquota (%)</p>
                        <input name='INSS' type="text" id='INSS' className='porcentagem' onKeyUp={handleChangeInTributo} autoComplete='off' placeholder="0.00"/>
                    </div>
                    <div className={styles.divInputsTributo}>
                        <p>Valor (R$)</p>
                        <input name='INSS_V' type="text" id='INSS_V' placeholder="0,00" readOnly disabled/>
                    </div>
                </Col>
                <Col className='col-2'>
                    <p className={styles.titleValorTributo}>COFINS</p>
                    <div className={styles.divInputsTributo}>
                        <p>Aliquota (%)</p>
                        <input name='COFINS' type="text" id='COFINS' className='porcentagem' onKeyUp={handleChangeInTributo} autoComplete='off' placeholder="0.00"/>
                    </div>
                    <div className={styles.divInputsTributo}>
                        <p>Valor (R$)</p>
                        <input name='COFINS_V' type="text" id='COFINS_V' placeholder="0,00" readOnly disabled/>
                    </div>
                </Col>
                <Col className='col-2'>
                    <p className={styles.titleValorTributo}>CSLL</p>
                    <div className={styles.divInputsTributo}>
                        <p>Aliquota (%)</p>
                        <input name='CSLL' type="text" id='CSLL' className='porcentagem' onKeyUp={handleChangeInTributo} autoComplete='off' placeholder="0.00"/>
                    </div>
                    <div className={styles.divInputsTributo}>
                        <p>Valor (R$)</p>
                        <input name='CSLL_V' type="text" id='CSLL_V' placeholder="0,00" readOnly disabled/>
                    </div>
                </Col>
                <Col className='col-2'>
                    <p className={styles.titleValorTributo}>PIS</p>
                    <div className={styles.divInputsTributo}>
                        <p>Aliquota (%)</p>
                        <input name='PIS' type="text" id='PIS' className='porcentagem' onKeyUp={handleChangeInTributo} autoComplete='off' placeholder="0.00"/>
                    </div>
                    <div className={styles.divInputsTributo}>
                        <p>Valor (R$)</p>
                        <input name='PIS_V' type="text" id='PIS_V' placeholder="0,00" readOnly disabled/>
                    </div>
                </Col>
                <Col className='col-2'>
                    <p className={styles.titleValorTributoResumo}>RESUMO</p>
                    <div className={styles.divInputsTributo}>
                        <p>Base (R$)</p>
                        <input name='BASE_V' type="text" id='BASE_V' placeholder="0,00" className='dinheiro' style={{fontWeight: "bold"}} readOnly disabled/>
                    </div>
                    <div className={styles.divInputsTributo}>
                        <p>Líquido (R$)</p>
                        <input name='LIQUIDO_V' type="text" id='LIQUIDO_V' placeholder="0,00" className='dinheiro' style={{fontWeight: "bold"}} readOnly disabled/>
                    </div>
                </Col>
            </Row>

            <Row className="mb-15-forms">
                <Col className="col-6">
                    <Form.Group>
                        <Form.Label>Cidade Serviço:</Form.Label>
                        <Select name='cidade' id='cidade' options={codCidadeOptions} value={codCidade} placeholder="Selecione a cidade" onChange={
                            (choice:any) => {
                                setCodCidade(choice)
                                let cidade:any = filtros.nf_cidades.filter(reg => reg.cod_cidade === Number(choice.value))[0]
                                if (!optanteSimplesNacional) {
                                    defineValueInInput("ISS", formatarPorcentagem(cidade.iss))
                                    handleCalcTributo("ISS")
                                    handleCalcValorLiquido()
                                }
                            }
                        } styles={customSelectStylesNfs} />
                        <Form.Control name='cidade_aux' type="text"id='cidade_aux' placeholder="" style={{display:"none"}}/>
                        <Form.Control.Feedback type="invalid">
                            Selecione a Cidade!
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className='col-2'>
                    <p className={styles.titleValorTributo} id='ISS_descricao'>ISS</p>
                    <div className={styles.divInputsTributo}>
                        <p>Aliquota (%)</p>
                        <input name='ISS' type="text" id='ISS' className='porcentagem' onKeyUp={handleChangeInTributo} autoComplete='off' placeholder="0.00" disabled={optanteSimplesNacional}/>
                    </div>
                    <div className={styles.divInputsTributo}>
                        <p>Valor (R$)</p>
                        <input name='ISS_V' type="text" id='ISS_V' placeholder="0,00" readOnly disabled/>
                    </div>
                </Col>
                <Col className='col-4'>
                    <Form.Group>
                        <Form.Control name='situacao' type="text" id='situacao' readOnly disabled/>
                    </Form.Group>
                </Col>
            </Row>

            <br></br>

            <Row className="mb-15-forms">
                <Col className='col-8'>
                    <Form.Group className="mt-0 mb-3">
                        <Form.Label>Informações da Nota</Form.Label>
                        <Form.Control as="textarea" rows={8} name='descriminacao' id='descriminacao' autoComplete='off' />
                        <Form.Control.Feedback type="invalid">
                            Preencha as Informações	da Nota!
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className="col-4">
                    <Form.Group>
                        <Form.Label>Mês de referência (MM/YYYY):</Form.Label>
                        <Form.Select name="referencia" id="referencia" aria-label="Selecione o mês de referência">
                            <option value="1">Mês anterior competência</option>
                            <option value="2">Mês igual competência</option>
                            <option value="0">Não informar mês automático</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            Ponto de #ref# não encontrada na descriminacao!
                        </Form.Control.Feedback>
                        <br></br>
                        <Form.Label style={{color: "grey"}}>Adicione #ref# (Ex.: 10/2024) no texto.</Form.Label>
                        <Form.Label style={{color: "grey"}}>Opcional #periodo# (Ex.: 01/10/2024 À 31/10/2024) no texto.</Form.Label>
                    </Form.Group>
                </Col>
            </Row>

            <Button type="submit" variant="success" style={{marginRight: "10px"}}>{idModelo === null ? "Cadastrar Modelo" : "Editar Modelo"}</Button>
            { idModelo !== null && <Button variant="danger" onClick={() => { deletar() }}>Deletar</Button> }

            </Form>
        </div>
    )
}
