import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify'

// Paginas
import Home from './pages/Home'
import DashboardPage from './pages/Dashboard'
import ColaboradoresPage from './pages/colaborador/Colaboradores'
import ColaboradorPage from './pages/colaborador/Colaborador'
import LoginPage from './pages/Login'
import EmpresasPage from './pages/painelControle/empresas/EmpresasPage'
import EmpresaCadastrarPage from './pages/painelControle/empresas/EmpresaCadastrarPage'
import CargosPage from './pages/painelControle/cargos/CargosPage';
import CargosCadastrarEditarPage from './pages/painelControle/cargos/CargosCadastrarEditarPage';
import CentroCustoPage from './pages/painelControle/centro_custo/CentroCustoPage';
import CentroCustoCadastrarPage from './pages/painelControle/centro_custo/CentroCustoCadastrarPage';
import LocalTrabalhoPage from './pages/painelControle/local_trabalho/LocalTrabalhoPage';
import LocalTrabalhoFormPage from './pages/painelControle/local_trabalho/LocalTrabalhoFormPage';
import SituacoesPage from './pages/painelControle/situacoes/SituacoesPage';
import DefinicoesAppPage from './pages/painelControle/definicoes_app/DefinicoesAppPage';
import UsuariosPage from './pages/painelControle/usuarios/UsuariosPage';
import UsuariosFormPage from './pages/painelControle/usuarios/UsuariosFormPage';

// NF
import NFSelecaoEmpresasPage from './pages/nf/SelecaoEmpresas';

// Despesas
import FrotaDespesasPage from './pages/despesas/frota/Custos';

import ErrorPage from "./pages/ErrorPage";
import ResetCss from './styles/reset_css'
import GlobalCss from './styles/global_css'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import SituacoesFormPage from './pages/painelControle/situacoes/SituacoesFormPage';
import ConsultarApuracaoPage from './pages/apuracao/ConsultarPage';
import ApuracaoPage from './pages/apuracao/ApuracaoPage';
import MarcacoesPage from './pages/pontoDigital/MarcacoesPage';
import OcorrenciasPage from './pages/pontoDigital/OcorrenciaPage';
import FolhaPontoPage from './pages/pontoDigital/FolhaPontoPage';
import NfEmpresasPage from './pages/nf/EmpresasPage';
import NfEmpresasFormPage from './pages/nf/EmpresasCadastraPage';
import NfClientesPage from './pages/nf/ClientesPage';
import NfClientesFormPage from './pages/nf/ClientesCadastrarPage';
import NfInicialPage from './pages/nf/Inicial';
import NfModelosPage from './pages/nf/NotaModelo';
import NfCadastrarModeloPage from './pages/nf/pageNotaModelo/NfsCadastrarModelo';
import NfTiposClientesPage from './pages/nf/ClientesTiposPage';
import NfClientesTiposFormPage from './pages/nf/ClientesTiposCadastrarPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'dashboard',
        element: <DashboardPage />
      },
      {
        path: 'colaboradores',
        element: <ColaboradoresPage />
      },
      {
        path: 'colaborador',
        element: <ColaboradorPage />
      },
      {
        path: 'ponto/marcacoes',
        element: <MarcacoesPage />
      },
      {
        path: 'ponto/ocorrencias',
        element: <OcorrenciasPage />
      },
      {
        path: 'ponto/folha',
        element: <FolhaPontoPage />
      },
      {
        path: 'ponto/consultar',
        element: <ConsultarApuracaoPage />
      },
      {
        path: 'ponto/apuracao',
        element: <ApuracaoPage />
      },
      {
        path: 'nfs',
        element: <NfInicialPage />
      },
      {
        path: 'nf',
        element: <NFSelecaoEmpresasPage />
      },
      {
        path: 'nf/empresas',
        element: <NfEmpresasPage />
      },
      {
        path: 'nf/empresas/consultar',
        element: <NfEmpresasFormPage />
      },
      {
        path: 'nf/clientes',
        element: <NfClientesPage />
      },
      {
        path: 'nf/modelos',
        element: <NfModelosPage />
      },
      {
        path: 'nf/modelo',
        element: <NfCadastrarModeloPage />
      },
      {
        path: 'nf/clientes/consultar',
        element: <NfClientesFormPage />
      },
      {
        path: 'nf/clientes/tipos',
        element: <NfTiposClientesPage />
      },
      {
        path: 'nf/clientes/tipo',
        element: <NfClientesTiposFormPage />
      },
      {
        path: 'despesas/frota',
        element: <FrotaDespesasPage />
      },
      {
        path: 'painelControle/empresas',
        element: <EmpresasPage />
      },
      {
        path: 'painelControle/empresas/cadastrar',
        element: <EmpresaCadastrarPage />
      },
      {
        path: 'painelControle/empresas/editar',
        element: <EmpresaCadastrarPage />
      },
      {
        path: 'painelControle/cargos',
        element: <CargosPage />
      },
      {
        path: 'painelControle/cargos/cadastrar',
        element: <CargosCadastrarEditarPage />
      },
      {
        path: 'painelControle/cargos/editar',
        element: <CargosCadastrarEditarPage />
      },
      {
        path: 'painelControle/centroCusto/cadastrar',
        element: <CentroCustoCadastrarPage />
      },
      {
        path: 'painelControle/centroCusto/editar',
        element: <CentroCustoCadastrarPage />
      },
      {
        path: 'painelControle/centroCusto',
        element: <CentroCustoPage />
      },
      {
        path: 'painelControle/localTrabalho',
        element: <LocalTrabalhoPage />
      },
      {
        path: 'painelControle/localTrabalho/cadastrar',
        element: <LocalTrabalhoFormPage />
      },
      {
        path: 'painelControle/localTrabalho/editar',
        element: <LocalTrabalhoFormPage />
      },
      {
        path: 'painelControle/situacoes',
        element: <SituacoesPage />
      },
      {
        path: 'painelControle/situacoes/cadastrar',
        element: <SituacoesFormPage />
      },
      {
        path: 'painelControle/situacoes/editar',
        element: <SituacoesFormPage />
      },
      {
        path: 'painelControle/definicoesApp',
        element: <DefinicoesAppPage />
      },
      {
        path: 'painelControle/usuarios',
        element: <UsuariosPage />
      },
      {
        path: 'painelControle/usuarios/cadastrar',
        element: <UsuariosFormPage />
      },
      {
        path: 'painelControle/usuarios/editar',
        element: <UsuariosFormPage />
      },
    ]
  },
  {
    path: '/login',
    element: <LoginPage />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ToastContainer containerId={1} />
    <RouterProvider router={router} />
    <ResetCss />
    <GlobalCss />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
