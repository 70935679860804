import { FormEvent, useEffect, useState } from "react"
import { getUrlSearchParams } from "../urlParams"
import removeAllErrorMsg from "../../components/funcRemoveAllErros"
import { toast } from "react-toastify"
import funcSleep from "../../components/funcSleep"
import { ApiEndpoints, reqPatch, reqPostNew, reqPut } from "../../config/apiClient"
import LoadingModal from "../../components/loadingModal"
import { Button, Col, Form, Row } from "react-bootstrap"
import $ from 'jquery'
import 'jquery-mask-plugin'
import { defineValueInInput } from "../../components/funcForInput"
import { iNfTiposClientesViewDto } from "./dto/nfClientes"

function NfClientesTiposFormPage() {
    const [loading, setLoading] = useState<boolean>(false)
    const [tipoDados, setTipoDados] = useState<iNfTiposClientesViewDto | any>({})

    useEffect(() => {
        $('.porcentagem').mask('000.00', {reverse: true});

        document.title = "Cadastrar NF Tipo Cliente | OB PORTUS"

        const urlDados = getUrlSearchParams()
        const id = urlDados.get("id")

        const getDados = async (id:number) => {
            setLoading(true)

            const dados = {
                id_tipo_cliente: Number(id)
            }

            const api = await reqPostNew(ApiEndpoints.nfTiposClientesConsultar, dados)

            if (api.status === 200) {
                const tipoCliente:iNfTiposClientesViewDto = api.data
                setTipoDados(tipoCliente)
                defineValueInInput('nome', tipoCliente.nome)
                defineValueInInput('irrf', tipoCliente.IRRF)
                defineValueInInput('inss', tipoCliente.INSS)
                defineValueInInput('cofins', tipoCliente.COFINS)
                defineValueInInput('csll', tipoCliente.CSLL)
                defineValueInInput('pis', tipoCliente.PIS)
            }

            setLoading(false)
        }

        if (id !== null) {
            getDados(Number(id))
            document.title = "Editar NF Tipo Cliente | OB PORTUS"
        }
    }, [])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        const form:any = event.target
        let formValidado = true

        let dados:iNfTiposClientesViewDto = {
            id_tipo_cliente: Object.values(tipoDados).length > 0 ? tipoDados?.id_tipo_cliente : null,
            nome: form.nome.value,
            IRRF: form.irrf.value,
            INSS: form.inss.value,
            COFINS: form.cofins.value,
            CSLL: form.csll.value,
            PIS: form.pis.value,
        }

        if (dados.nome === "") {
            form.nome.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.IRRF === "") {
            form.irrf.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.INSS === "") {
            form.inss.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.COFINS === "") {
            form.cofins.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.CSLL === "") {
            form.csll.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.PIS === "") {
            form.pis.nextElementSibling.style.display = "block"
            formValidado = false
        }

        console.info(dados)

        if (!formValidado) {
            return
        }

        if (Object.values(tipoDados).length === 0) {
            cadastrar(dados)
        }

        if (Object.values(tipoDados).length > 0) {
            editar(dados)
        }
    
    }

    async function cadastrar(dados:iNfTiposClientesViewDto) {

        setLoading(true)

        const api = await reqPut(ApiEndpoints.nfTiposClientesCadastrar, dados)

        if (api.status === 201) {
            console.log(api.data)

            toast.success('Tipo cadastrado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/nf/clientes/tipos`)
        }
        setLoading(false)

    }

    async function editar(dados: iNfTiposClientesViewDto) {
        setLoading(true)

        const api = await reqPatch(ApiEndpoints.nfTiposClientesEditar, dados)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Tipo editado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/nf/clientes/tipos`)
        }

        setLoading(false)
    }

    async function handleExcluirTipo() {
        if (!window.confirm('Tem certeza que deseja excluir o Tipo de Cliente?')) {
            return
        }

        const dados = {
            id_tipo_cliente: tipoDados.id_tipo_cliente
        }

        setLoading(true)

        const api = await reqPostNew(ApiEndpoints.nfTiposClientesExcluir, dados)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Tipo excluído com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/nf/clientes/tipos`)
        }

        setLoading(false)

    }
    
    return (
        <div id="main">
            <LoadingModal show={loading} handleClose={(event:any) => {
                setLoading(!loading)
            }} />
            <div className="div-row-space-between">
            <h1 id="titleMain">NF Tipo Cliente</h1>
            <Button variant="secondary" onClick={() => {window.location.assign(`/nf/clientes/tipos`)}}>Voltar</Button>
            </div>
            <br></br>
            <Form id='formNfTipoCliente' onSubmit={handleSubmit} noValidate>

                <Row className="mb-15-forms">
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Tipo Cliente:</Form.Label>
                            <Form.Control name='nome' type="text" id='nome' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Nome!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-2">
                        <Form.Group>
                            <Form.Label>IRRF (%):</Form.Label>
                            <Form.Control name='irrf' type="text" id='irrf' placeholder="0.00 %" className="porcentagem"/>
                            <Form.Control.Feedback type="invalid">
                                Informe o IRRF!
                            </Form.Control.Feedback>
                            <input name='iss_aux' type="text" id='iss_aux' className="porcentagem" style={{display: "none"}} />
                        </Form.Group>
                    </Col>

                    <Col className="col-2">
                        <Form.Group>
                            <Form.Label>INSS (%):</Form.Label>
                            <Form.Control name='inss' type="text" id='inss' placeholder="0.00 %" className="porcentagem"/>
                            <Form.Control.Feedback type="invalid">
                                Informe o INSS!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-2">
                        <Form.Group>
                            <Form.Label>COFINS (%):</Form.Label>
                            <Form.Control name='cofins' type="text" id='cofins' placeholder="0.00 %" className="porcentagem"/>
                            <Form.Control.Feedback type="invalid">
                                Informe o COFINS!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-2">
                        <Form.Group>
                            <Form.Label>CSLL (%):</Form.Label>
                            <Form.Control name='csll' type="text" id='csll' placeholder="0.00 %" className="porcentagem"/>
                            <Form.Control.Feedback type="invalid">
                                Informe o CSLL!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col className="col-2">
                        <Form.Group>
                            <Form.Label>PIS (%):</Form.Label>
                            <Form.Control name='pis' type="text" id='pis' placeholder="0.00 %" className="porcentagem"/>
                            <Form.Control.Feedback type="invalid">
                                Informe o PIS!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                {
                    Object.values(tipoDados).length === 0 &&
                    <Button variant="success" type="submit" disabled={loading}>{loading ? 'Aguarde ...' : 'Cadastrar'}</Button>
                }

                {
                    Object.values(tipoDados).length > 0 &&
                    <Button variant="success" type="submit" disabled={loading} style={{marginRight: '10px'}}>{loading ? 'Aguarde ...' : 'Editar'}</Button>
                }

                {
                    Object.values(tipoDados).length > 0 &&
                    <Button variant="danger" disabled={loading} style={{marginRight: '10px'}} onClick={() => {handleExcluirTipo()}}>{loading ? 'Aguarde ...' : 'Deletar'}</Button>
                }
            </Form>
        </div>
    )
}

export default NfClientesTiposFormPage