import { FormEvent, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import removeAllErrorMsg from "../../../../components/funcRemoveAllErros";
import { ApiEndpoints } from "../../../../config/apiClient";
import { toast } from "react-toastify";
import { getCookie } from "../../../../config/cookies";

interface iPropsModal {
    show: boolean,
    handleClose: any,
    dados: any
}

export default function ApuracaoModalDownload ({show, handleClose, dados}: iPropsModal) {

    const [loading, setLoading] = useState<boolean>(false)

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        const form:any = event.target
        let formValidado = true

        let dadosDownload:any = {
            id_colaborador: String(dados.dados.colaborador.id_colaborador),
            data_inicial: dados.filtro.data_inicial,
            data_final: dados.filtro.data_final,
            formato: form.formato.value,
            apenas_totais: 0,
            mostrar_totais: form.mostrar_totais.checked === true ? 1 : 0,
        }
        console.log(dadosDownload)

        if (!formValidado) {
            return
        }

        download(dadosDownload)
    }

    async function download(dadosDownload:any) {
        setLoading(true)

        var token = getCookie('token')
        let request = new XMLHttpRequest();

        request.responseType = "blob";
        request.open("POST", ApiEndpoints.apuracaoCalcularDadosDownload.endpoint, true)
        request.setRequestHeader("Content-Type", "application/json")
        request.setRequestHeader("Authorization", `Bearer ${token}`)
        request.send(JSON.stringify(dadosDownload))

        request.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                const imageURL = window.URL.createObjectURL(this.response)
                const anchor = document.createElement("a")
                anchor.href = imageURL
                const nome_completo = String(dados.dados.colaborador.nome_completo).normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                const nome_arquivo = `${dados.dados.colaborador.matricula} - ${nome_completo} ${dadosDownload.data_inicial}-${dadosDownload.data_final}`
                anchor.download = `${nome_arquivo}.${dadosDownload.formato === 'pdf' ? 'pdf' : 'xlsx'}`
                document.body.appendChild(anchor)
                anchor.click()
                toast.success('Download efetuado com sucesso!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            } else if (this.status === 401 || this.status === 400) {
                toast.error('Erro Download!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            }
            setLoading(false)
        }

    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Download Relatório</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id={`formDownload`} onSubmit={handleSubmit} noValidate>
                    <Row>
                        <Col className="col-12">
                            <Form.Group>
                                <Form.Label>Formato de Dados:</Form.Label>
                                <Form.Select name="formato" id="formato" aria-label="Selecione o Formato">
                                    <option value="pdf">PDF</option>
                                    <option value="excel">Excel</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col className="col-12">
                            <div>
                                <br/>
                                <label style={{marginRight: "10px"}}>Mostrar calculo de Horas?</label>
                                <input name='mostrar_totais' type="checkbox" id='mostrar_totais'/>
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Button type="submit" disabled={loading}>{loading ? "Aguarde..." : "Download"}</Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )

} 