import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { createColumnHelper } from "@tanstack/react-table"
import { ApiEndpoints, reqGetNew } from "../../config/apiClient"
import DataTable from "../../components/dataTable/DataTable"
import { iNfTiposClientesViewDto } from "./dto/nfClientes"

const columnHelper = createColumnHelper<iNfTiposClientesViewDto>()

const defaultColumns = [
    columnHelper.accessor('id_tipo_cliente', {
        header: 'ID',
        size: 10,
        minSize: 10,
        maxSize: 150
    }),
    columnHelper.accessor('nome', {
        header: 'Tipo'
    }),
    columnHelper.accessor(row => `IRRF ${row.IRRF}, INSS ${row.INSS}, COFINS ${row.COFINS}, CSLL ${row.CSLL}, PIS ${row.PIS}`, {
        header: 'Valores',
    }),
]

const defaultOrder:any = []

function NfTiposClientesPage() {
    const [clientes, setClientes] = useState<iNfTiposClientesViewDto[]>([])

    useEffect(() => {
        document.title = "NF Tipos Clientes | OB PORTUS"

        const listarDados = async () => {
            const api = await reqGetNew(ApiEndpoints.nfTiposClientesConsultarTodos)

            if (api.status === 200) {
                const clientesAux:iNfTiposClientesViewDto[] = api.data
                setClientes(clientesAux)
            }
        }

        listarDados()
    }, [])

    function tableMiddleClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = clientes[idx]
        const link = `/nf/clientes/tipo?id=${reg.id_tipo_cliente}`

        if (event.button === 1) {
            window.open(link)
        }
    }

    function tableClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = clientes[idx]
        const link = `/nf/clientes/tipo?id=${reg.id_tipo_cliente}`

        if (event.button === 0) {
            window.location.assign(link)
        }
    }

    return (
        <div id="main">
            <div className="div-row-space-between">
                <h1 id="titleMain">NF Tipos Clientes</h1>
                <div className="div-row">
                    <Button onClick={() => {
                        window.location.assign(`/nf/clientes/tipo`)
                    }}>Cadastrar</Button>
                </div>
            </div>
            <br></br>
            {<DataTable dados={clientes} tableSize={'auto'} defaultColumns={defaultColumns} tableMiddleClickhandler={tableMiddleClickhandler} tableClickhandler={tableClickhandler} defaultOrder={defaultOrder} />}
        </div>
    )
}

export default NfTiposClientesPage