import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { ApiEndpoints, reqPostNew } from "../../config/apiClient";
import { NfModelosViewDto } from "./dto/nf_modelos";
import { createColumnHelper } from "@tanstack/react-table";
import DataTable from "../../components/dataTable/DataTable";
import dayjs from "dayjs";
import LoadingModal from "../../components/loadingModal";
import $ from 'jquery'
import 'jquery-mask-plugin'
import { defineValueInInput } from "../../components/funcForInput";

const columnHelper = createColumnHelper<NfModelosViewDto>()

const defaultColumns = [
    columnHelper.accessor('id_modelo', {
        header: 'ID',
        size: 10,
        minSize: 10,
        maxSize: 150
    }),
    columnHelper.accessor('nome', {
        header: 'Modelo',
        size: 280,
        minSize: 150,
        maxSize: 300
    }),
    columnHelper.accessor(row => `${row.id_empresa.nome}`, {
        header: 'Empresa'
    }),
    columnHelper.accessor(row => `${row.id_cliente.nome} CNPJ ${row.id_cliente.cnpj}`, {
        header: 'Cliente',
        size: 450,
        minSize: 150,
        maxSize: 450
    }),
    columnHelper.accessor('valor', {
        header: 'Valor Serviços'
    }),
    columnHelper.accessor(row => `${row.dia_data_competencia.length === 1 ? `0${row.dia_data_competencia}` : row.dia_data_competencia}/mm/yyyy`, {
        header: 'Dia Data'
    }),
    columnHelper.accessor(row => `${row.tipo === 1 ? "Mensal" : "Apenas Modelo"}`, {
        header: 'Tipo'
    }),
    columnHelper.accessor(row => dayjs(row.data_criacao).format("DD/MM/YYYY HH:mm:ss"), {
        header: 'Criado em'
    }),
]

const defaultOrder:any = []

function formatarDinheiro(valor:string):string {
    defineValueInInput("dinheiro_aux", valor)
    return $("#dinheiro_aux").masked(valor)
}

export default function NfModelosPage() {

    useEffect(() => {
        $('.dinheiro').mask('###.###.###.###.###.###.##0,00', {reverse: true, placeholder: "0,00"});
        document.title = "NF Modelos | OB PORTUS"
        const listarDados = async () => {
            setLoading(true)
            const api = await reqPostNew(ApiEndpoints.nfModelosConsultar, {})
            if (api.status === 200) {
                const dadosAux:NfModelosViewDto[] = api.data
                for (let idx in dadosAux) {
                    dadosAux[idx].valor = `R$ ${formatarDinheiro(dadosAux[idx].valor)}`
                }
                setModelos(dadosAux)
            }
            setLoading(false)
        }
        listarDados()
    }, [])

    const [modelos, setModelos] = useState<NfModelosViewDto[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    function tableMiddleClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = modelos[idx]
        const link = `/nf/modelo?id=${reg.id_modelo}`

        if (event.button === 1) {
            window.open(link)
        }
    }

    function tableClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = modelos[idx]
        const link = `/nf/modelo?id=${reg.id_modelo}`

        if (event.button === 0) {
            window.location.assign(link)
        }
    }

    return (
        <div id="main">
            <Form.Control name='dinheiro_aux' type="text" id='dinheiro_aux' className='dinheiro' placeholder="0,00" style={{display: "none"}} />
            <LoadingModal show={loading} handleClose={(event:any) => { setLoading(!loading) }} />
            <div className="div-row-space-between">
                <h1 id="titleMain">NFs Mensais | Modelos</h1>
                <Button onClick={() => { window.location.assign(`/nf/modelo`) } }>Cadastrar</Button>
            </div>
            <br></br>
            {<DataTable dados={modelos} tableSize={'auto'} defaultColumns={defaultColumns} tableMiddleClickhandler={tableMiddleClickhandler} tableClickhandler={tableClickhandler} defaultOrder={defaultOrder} />}
        </div>
    )

}