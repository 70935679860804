import styles from '../styles/apuracao.module.css'
import { Col, Row } from "react-bootstrap";
import { iDiaApuracao, iDiaApuracaoRegistro } from "../dto/dados-apuracao.dto";
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import React, { useState } from 'react';
import ApuracaoPagePainelDetalhes from './painelDetalhes/ApuracaoPagePainelDetalhes';
import ApuracaoState from '../ApuracaoState';
import { ApiEndpoints } from '../../../config/apiClient';
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

interface iProps {
    dia: iDiaApuracao,
    dados: any,
    apuracaoState: ApuracaoState,
    handleOnUpdate: any
}

export default function ApuracaoPageDia({dia, dados, apuracaoState, handleOnUpdate}:iProps) {

    const [painelDetalhes, setPainelDetalhes] = useState<boolean>(false)

    function definirObsDia(dia:iDiaApuracao):string {
        let obs = ""

        let qtdFlags = listarFlagsDia(dia)
        if (qtdFlags.length > 0) {
            obs = obs + ` Referências (${qtdFlags.length})`
        }

        let qtdObs = listarObservacoesDia(dia)
        if (qtdObs.length > 0) {
            obs = obs + ` Observação (${qtdObs.length})`
        }

        let qtdTrocasTurno = listarTrocaTurnoDia(dia)
        if (qtdTrocasTurno.length > 0) {
            obs = obs + ` Troca de Turno (${qtdTrocasTurno.length})`
        }
        
        let qtdAjustes = listarAjustesPendentesDiaTodos(dia)
        if (qtdAjustes.length > 0) {
            obs = obs + ` Ajustes (${qtdAjustes.length})`
        }

        let qtdOcorrencias = listarOcorrenciasPendentesDiaTodos(dia)
        if (qtdOcorrencias.length > 0) {
            obs = obs + ` Ocorrências (${qtdOcorrencias.length})`
        }
        
        let qtdOcorrenciasForaLocalTrabalho = listarOcorrenciasForaLocalTrabalhoDia(dia)
        if (qtdOcorrenciasForaLocalTrabalho.length > 0) {
            obs = obs + ` Fora Local de Trabalho (${qtdOcorrenciasForaLocalTrabalho.length})`
        }

        return obs
    }

    const escalaDivStyle:React.CSSProperties = {padding: "0px 8px", position: 'absolute', backgroundColor: '#264653', color: 'white', top: '-6px', fontSize: '13px'}

    return (
        <div style={{position: 'relative'}}>
            {
                listarEscalaHistoricoDia(dia) !== null &&
                <div style={escalaDivStyle}>
                    <p><a href={`${ApiEndpoints.baseUrl}/dashboard/ponto/escala/editar/${listarEscalaHistoricoDia(dia).codigo}`} rel="noreferrer" style={{textDecoration: "none", color: "white"}} target='_blank'>Escala: {listarEscalaHistoricoDia(dia).codigo} - {listarEscalaHistoricoDia(dia).descricao}</a></p>
                </div>
            }
            <div className={styles.apuracaoDia} style={{
                backgroundColor: dia.horas_extras > 0 ? "#FFFDBF" : dia.horas_faltas > 0 ? "rgb(254, 197, 187)" : undefined
            }} onClick={(event:any) => { setPainelDetalhes(!painelDetalhes) } }>
                <Row>
                    <Col className="col-1"><p>{dayjs(dia.data).format("DD/MM")} {dia.data_dia_nome.substring(0,3)} {dia.data_feriado === 1 ? 'FERIADO' : ''}</p></Col>
                    <Col className="col-3"><p>{dia.registros.map((reg:iDiaApuracaoRegistro, index:any) => (`${reg.data_hora.substring(0, 5)} `))} {dia.obs === null ? '' : dia.obs.text}</p></Col>
                    <Col className='col-1'>
                        <div className='div-row'>
                            {
                                listarAjustesPendentesDia(dia).length > 0 &&
                                <span style={{color: 'white', backgroundColor: "#264653", padding: '0px 4px 0px 4px', borderRadius: '4px', marginRight: '5px'}}>{listarAjustesPendentesDia(dia).length}</span>
                            }
                            {
                                listarOcorrenciasPendentesDia(dia).length > 0 &&
                                <span style={{color: 'white', backgroundColor: "red", padding: '0px 4px', borderRadius: '4px', marginRight: '5px'}}>{listarOcorrenciasPendentesDia(dia).length}</span>
                            }
                            {
                                listarOcorrenciasForaLocalTrabalhoDia(dia).length > 0 &&
                                <span style={{color: 'black', backgroundColor: "#ffc300", padding: '0px 4px', borderRadius: '4px'}}>{listarOcorrenciasForaLocalTrabalhoDia(dia).length}</span>
                            }
                        </div>
                    </Col>
                    <Col className="col-4"><p>HT - {dia.HT} | HE - {dia.HE} | HF - {dia.HF} | HN - {dia.AN} | HI - {dia.HI}</p></Col>
                    <Col className="auto" style={{fontSize: "12px"}}><p>{definirObsDia(dia)}</p></Col>
                </Row>
            </div>
            <ApuracaoPagePainelDetalhes dia={dia} visivel={painelDetalhes} dados={dados} apuracaoState={apuracaoState} onUpdate={handleOnUpdate} />
        </div>
    )

    function listarOcorrenciasPendentesDia(dia:iDiaApuracao):any[] {
        let ocorrencias:any[] = []

        let data_inicial = dayjs(`${dia.data} 00:00:00`)
        let data_final = dayjs(`${dia.data} 23:59:59`)
        if (dia.escala.tipo_diurna_noturna === 1) {
            data_inicial = dayjs(`${dia.data} 12:00:00`)
            data_final = data_inicial.add(1, 'day')
        }

        for (let reg of dados.dados.ocorrencias) {
            let data_criacao_marcacao = dayjs(reg.data_criacao)
            if (data_criacao_marcacao.isSameOrAfter(data_inicial) && data_criacao_marcacao.isSameOrBefore(data_final)) {
                if (reg.id_status.id_status === 1) {
                    ocorrencias.push(reg)
                }
            }
        }
        
        return ocorrencias
    }

    function listarOcorrenciasPendentesDiaTodos(dia:iDiaApuracao):any[] {
        let ocorrencias:any[] = []

        let data_inicial = dayjs(`${dia.data} 00:00:00`)
        let data_final = dayjs(`${dia.data} 23:59:59`)
        if (dia.escala.tipo_diurna_noturna === 1) {
            data_inicial = dayjs(`${dia.data} 12:00:00`)
            data_final = data_inicial.add(1, 'day')
        }

        for (let reg of dados.dados.ocorrencias) {
            let data_criacao_marcacao = dayjs(reg.data_criacao)
            if (data_criacao_marcacao.isSameOrAfter(data_inicial) && data_criacao_marcacao.isSameOrBefore(data_final)) {
                if (reg.id_tipo_pendencia.necessario_justificativa === 1) {
                    ocorrencias.push(reg)
                }
            }
        }
        
        return ocorrencias
    }
    
    function listarOcorrenciasForaLocalTrabalhoDia(dia:iDiaApuracao):any[] {
        let ocorrencias:any[] = []

        let data_inicial = dayjs(`${dia.data} 00:00:00`)
        let data_final = dayjs(`${dia.data} 23:59:59`)
        if (dia.escala.tipo_diurna_noturna === 1) {
            data_inicial = dayjs(`${dia.data} 12:00:00`)
            data_final = data_inicial.add(1, 'day')
        }

        for (let reg of dados.dados.ocorrencias) {
            let data_criacao_marcacao = dayjs(reg.data_criacao)
            if (data_criacao_marcacao.isSameOrAfter(data_inicial) && data_criacao_marcacao.isSameOrBefore(data_final)) {
                if (reg.id_tipo_pendencia.id_tipo_pendencia === 17) {
                    ocorrencias.push(reg)
                }
            }
        }
        
        return ocorrencias
    }

    function listarAjustesPendentesDia(dia:iDiaApuracao):any[] {
        let ajusteDia:any[] = []

        let data_inicial = dayjs(`${dia.data} 00:00:00`)
        let data_final = dayjs(`${dia.data} 23:59:59`)
        if (dia.escala.tipo_diurna_noturna === 1) {
            data_inicial = dayjs(`${dia.data} 12:00:00`)
            data_final = data_inicial.add(1, 'day')
        }

        for (let ajuste of dados.dados.ajustes) {
            let horarios = ajuste.horarios
            for (let reg of horarios) {
                let data_hora_ajuste = dayjs(`${reg.data_ajuste} ${reg.hora_ajuste}`)
                if (data_hora_ajuste.isSameOrAfter(data_inicial) && data_hora_ajuste.isSameOrBefore(data_final) && ajuste.a_id_status === "1") {
                    ajusteDia.push(reg)
                    break
                }
            }
        }

        return ajusteDia
    }

    function listarAjustesPendentesDiaTodos(dia:iDiaApuracao):any[] {
        let ajusteDia:any[] = []

        let data_inicial = dayjs(`${dia.data} 00:00:00`)
        let data_final = dayjs(`${dia.data} 23:59:59`)
        if (dia.escala.tipo_diurna_noturna === 1) {
            data_inicial = dayjs(`${dia.data} 12:00:00`)
            data_final = data_inicial.add(1, 'day')
        }

        for (let ajuste of dados.dados.ajustes) {
            let horarios = ajuste.horarios
            for (let reg of horarios) {
                let data_hora_ajuste = dayjs(`${reg.data_ajuste} ${reg.hora_ajuste}`)
                if (data_hora_ajuste.isSameOrAfter(data_inicial) && data_hora_ajuste.isSameOrBefore(data_final)) {
                    ajusteDia.push(reg)
                    break
                }
            }
        }

        return ajusteDia
    }

    function listarObservacoesDia(dia:iDiaApuracao):any[] {
        let obs:any[] = []
        for (let reg of dados.dados.observacoes) {
            if (reg.data_dia === dia.data) {
                obs.push(reg)
            }
        }
        return obs
    }

    function listarTrocaTurnoDia(dia:iDiaApuracao):any[] {
        let troca_turno:any[] = []
        for (let reg of dados.dados.trocas_turno) {
            if (reg.data_dia === dia.data) {
                troca_turno.push(reg)
                break
            }
            if (reg.data_dia_aux === dia.data) {
                troca_turno.push(reg)
                break
            }
        }
        return troca_turno
    }

    function listarFlagsDia(dia:iDiaApuracao):any[] {
        let flag:any[] = []
        for (let reg of dados.dados.flags) {
            if (reg.data_dia === dia.data) {
                flag.push(reg)
                break
            }
        }
        return flag
    }

    function listarEscalaHistoricoDia(dia:iDiaApuracao):any | null {
        let escala:any = null
        let data_dia = dayjs(dia.data)
        let data_dia_anterior = data_dia.subtract(1, 'day').format("YYYY-MM-DD")

        let dia_anterior = null
        for (let reg of dados.calendario) {
            if (reg.data === data_dia_anterior) {
                dia_anterior = reg
                break
            }
        }

        if (dia_anterior === null) {

            if (dia.data === dados.filtro.data_inicial) {
                return dia.escala
            }

            return escala
        }

        if (dia.escala.id_escala !== dia_anterior.escala.id_escala) {
            escala = dia.escala
        }

        return escala
    }
}