import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
body {
    background-color: #fafafa;
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

.div-row-space-between {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.div-row {
    display: flex;
    align-content: center;
    align-items: center;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-15-forms {
    margin-bottom: 15px;
    margin-right: -10px;
}

.modalApuracaoRight {
    margin: 0;
    position: fixed;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.modalDialogApuracaoRight {
    position: fixed;
    right: 0;
    margin: 0;
    height: 100vh;
    max-width: 100vw;
    width: 32vw;
}

.modalDialogApuracaoRightModalAlterarEscala {
    position: fixed;
    right: 0;
    margin: 0;
    height: 100vh;
    max-width: 100vw;
    width: 40vw;
}

.modalContentApuracaoRight {
    height: 100vh;
    overflow-y: auto;
    border-radius: 0px;
}

.modalContentApuracaoRight .modal-body {
    width: 85%;
}

@media (max-width: 1750px) {
    .modalDialogApuracaoRight {
        width: 28vw;
    }
    .modalDialogApuracaoRightModalAlterarEscala {
        width: 40vw;
    }
    .modalContentApuracaoRight .modal-body {
        width: 100%;
    }
}
`;