import { FormEvent, useEffect, useState } from "react";
import { Button, Col, Form, Modal, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import styles from './styles/importacao.module.css'
import removeAllErrorMsg from "../../components/funcRemoveAllErros";
import { getCookie } from "../../config/cookies";
import { ApiEndpoints, reqPostNew } from "../../config/apiClient";
import { defineValueInInput } from "../../components/funcForInput";

interface iProps {
    show: boolean
    handleClose: any,
    handleUpdate: any,
}

export default function NfModalImportarPlanilhaClientes ({show, handleClose, handleUpdate}:iProps) {
    const [loading, setLoading] = useState<boolean>(false)
    const [resultados, setResultados] = useState<any[]>([])

    useEffect(() => {
        // eslint-disable-next-line
    }, [])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        const form:any = event.target
        let formValidado = true

        let dadosImportar = {
            planilha: form.planilha.files,
        }
        console.log(dadosImportar)

        if (dadosImportar.planilha.length === 0) {
            form.planilha.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (!formValidado) {
            return
        }

        importar(dadosImportar)
    }

    async function importar(dadosImportar:any) {
        setLoading(true)

        const token = getCookie("token")
        let dados = new FormData()
        dados.append('planilha', dadosImportar.planilha[0])

        try {
            let api:Response
            let apiData = await fetch(
                ApiEndpoints.nfImportarPlanilhaClientes.endpoint,
                {
                    method: "PUT",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                    body: dados
                }
            ).then(response => {
                api = response
                return response.json()
            })
            if (api!.status === 200) {
                console.log(apiData)
                toast.success("Importação finalizada com sucesso!", {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                setResultados(apiData['resultados'])
                if (apiData['ja_esta_sendo_importado'] === true) {
                    toast.info("O restante da importação está sendo realizada em segundo plano!", {
                        containerId: 1,
                        position: 'top-center',
                        theme: 'dark',
                        autoClose: 13000,
                    })
                } else {
                    let todos_importados = true
                    let todos_resultados:any[] = apiData['resultados']
                    for (let reg of todos_resultados) {
                        if (reg.cliente_cadastrado === false && reg.mensagem !== 'CNPJ inválido!') {
                            todos_importados = false
                            break
                        }
                    }
                    if (!todos_importados) {
                        toast.info("O restante da importação está sendo realizada em segundo plano!", {
                            containerId: 1,
                            position: 'top-center',
                            theme: 'dark',
                            autoClose: 13000,
                        })
                    }
                }
                handleUpdate()
            } else {
                toast.warn(String(apiData.message), {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            }
        } catch (error) {
            console.log(error)
            toast.warn('Não foi possível importar, verifique se o layout está correto ou contate o suporte!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        }

        setLoading(false)
    }

    const popover = (
        <Popover id="popover-basic" style={{minWidth: "600px"}}>
            <Popover.Header as="h3">Exemplo Layout (Colunas)</Popover.Header>
            <Popover.Body>
                <Row>
                    <Col className="col-12">
                        <p><b>id_empresa*:</b> Número, Ex.: 1-R2S SEGURANCA OU 2-OB PORTUS SOLUTIONS</p>
                        <p><b>cnpj*:</b> 12345678999999</p>
                        <p><b>nome*:</b> NOME EMPRESA COMPLETO</p>
                        <p><b>nome_fantasia*:</b> NOME FANTASIA</p>
                        <p>logradouro: RUA EXEMPLO</p>
                        <p>numero: 123</p>
                        <p>complemento: EX</p>
                        <p>bairro: EXEMPLO 123</p>
                        <p>cep: 17800-000</p>
                        <p>cidade: Adamantina</p>
                        <p>uf: SP</p>
                        <p>telefone: (99) 12345-6789</p>
                        <p>email: teste@gmail.com</p>
                        <p>inscricao_municipal: 12345</p>
                        <p>inscricao_estadual: 12345</p>
                        <p><b>tipo_cliente*:</b> "Privado" OU "Municipal/Estadual" OU "Federal"</p>
                        <p><b>consultar_endereco*:</b> "Sim" OU "Não"</p>
                        <br/>
                        <p><b>Obs.:</b> Ao consultar o endereço são preenchidas as seguintes colunas: nome, nome_fantasia, logradouro, numero, complemento, bairro, cep, cidade, uf, telefone, email.</p>
                        <p>Fique atento com os dados do endereço de cada cliente, pois essas informações são usadas para emitir as NFs.</p>
                    </Col>
                </Row>
            </Popover.Body>
        </Popover>
    );

    async function handleCancelar():Promise<void> {
        if (!window.confirm("Tem certeza que deseja cancelar a importação?")) {
            return
        }

        setLoading(true)
        const api = await reqPostNew(ApiEndpoints.nfImportarPlanilhaClientesCancelar, {})
        setLoading(false)
        if (api.status === 200) {
            toast.success("Importação cancelada com sucesso!", {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Cadastrar - Importar planilha de Clientes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id={`formImportar`} onSubmit={handleSubmit} noValidate>
                    <Row>
                        <Col className="col-12">
                            <Form.Group>
                                <Form.Label>Arquivo:</Form.Label>
                                <Form.Control type="file" name="planilha" id="planilha" aria-label="Selecione a Planilha" accept=".xlsx"></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Selecione o arquivo!
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    { resultados.length === 0 && <Button type="submit" disabled={loading} style={{marginRight: "10px"}}>{loading ? "Aguarde..." : "Importar"}</Button> }
                    { resultados.length !== 0 && <Button variant="secondary" disabled={loading} onClick={() => {setResultados([]); defineValueInInput("planilha", "")}} style={{marginRight: "10px"}}>{loading ? "Aguarde..." : "Resetar"}</Button> }
                    <Button variant="danger" disabled={loading} style={{marginRight: "10px"}} onClick={() => { handleCancelar() }}>{loading ? "Aguarde..." : "Cancelar"}</Button>
                    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                        <Button variant="success">Layout</Button>
                    </OverlayTrigger>
                </Form>
                <br></br>
                {resultados.length !== 0 && <h2 style={{fontWeight: "bold"}}>Resultados da importação: {resultados.filter(reg => reg.cliente_cadastrado === true).length} de {resultados.length}</h2> }
                <div>
                    {
                        resultados.map((reg:any, idx:number) => (
                            <Row key={idx} className={styles.rowResultado}>
                                <Col className="col-4">
                                    <p>Linha ({reg.row})</p>
                                    <p>CNPJ: {reg.cnpj}</p>
                                    <p>Nome Fantasia: {reg.nome_fantasia}</p>
                                </Col>
                                {
                                    reg.cliente_cadastrado === true &&
                                    <Col className="col-8">
                                        <p>Status</p>
                                        <p>{reg.mensagem}</p>
                                        <a href={`/nf/clientes/consultar?id=${reg.id_cliente}`} target="_blank" rel="noreferrer">Ir para cadastro</a>
                                    </Col>
                                }
                                {
                                    reg.cliente_cadastrado === false &&
                                    <Col className="col-8">
                                        <p>Status (ERRO)</p>
                                        <p>{reg.mensagem}</p>
                                    </Col>
                                }
                            </Row>
                        ))
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}